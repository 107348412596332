//modules
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  Link,
  Divider
} from "@material-ui/core";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { toast } from "react-toastify";
import { SpinnerDiamond } from "spinners-react";
//components
import { api } from "../helpers/axios";
import { useStyles } from "../styles/styles";
import { makeString } from "../helpers/makeString";
import { abeeQuestionOrder } from "../helpers/abeeQuestionOrder";
//images
// import CleverButton from "../styles/images/LogInWithClever.png";
import smallLogo from "../styles/images/small-logo.png";

export const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(null);
  const [stateParam, setStateParam] = useState();

  //the id of the first question to be displayed for part II
  const firstQuestionId = "59209"

  useEffect(() => {
    let stateVal = makeString(36);
    setStateParam(stateVal);
  },
    [setStateParam]
  );

  const handleClickShowPassword = () => {
    if (!showPassword) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }

  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.fullscreen}>
      <Grid >
        <Paper elevation={3} className={classes.loginPaper}>
          <Grid align="center">
            <img src={smallLogo} className={classes.loginLogo} alt="inlier logo" />
            <Divider />
            <Typography component="h3" className={classes.loginHeaderTypography}>
              Sign in
            </Typography>
          </Grid>
          {loading === true && (
            <div style={{ width: "100%", textAlign: "center" }}>
              <SpinnerDiamond
                style={{
                  marginTop: "1em",
                  margin: "0 auto"
                }}
                color="#22223B"
                size={50}
                thickness={100}
                speed={100}
                secondaryColor="rgba(0, 0, 0, 0.44)"
              />
            </div>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={e => {
              setUsername(e.target.value);
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            onChange={e => {
              setPassword(e.target.value);
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            style={{ background: "#2A353E" }}
            onClick={async e => {
              e.preventDefault();
              setLoading(true)
              //axios call to login route
              api
                .post(
                  `/auth/login`,
                  { username, password },
                  {
                    withCredentials: true,
                    headers: {
                      // "Content-Type": "application/json"
                    }
                  }
                )
                .then(async response => {
                  if (response && response.data && response.data.message.user.userType === 1 || response.data.message.user.userType === 3) {
                    window.localStorage.setItem(
                      "iat",
                      response.data.message.accessToken
                    );
                    window.localStorage.setItem(
                      "iun",
                      response.data.message.user.username
                    );
                    window.localStorage.setItem(
                      "userType",
                      response.data.message.user.userType
                    );
                    window.localStorage.setItem(
                      "abeeType",
                      response.data.message.user.abeeType
                    );
                    window.localStorage.setItem(
                      "grade",
                      response.data.message.user.grade
                    );

                    return response.data.message.accessToken;
                  } else {
                    throw Error("This platform is only for student and caregiver accounts.");
                  }
                })
                .then((accessToken) => {
                  return api.get(`/student-app/status`, {
                    headers: {
                      "Content-type": "application/json",
                      authorization: `Bearer ${accessToken}`
                    }
                  })
                })
                .then(res => {
                  if (res.status === 200 && res.data && res.data.message && res.data.message.answers && res.data.message.answers.length > 0) {
                    return res.data.message.answers
                  }
                })
                .then((myresp) => {
                  let statusArray = [];
                  if (myresp && myresp.length > 0) {
                    let skip = myresp.find((i) => {
                      if (i.questionId === "2") {
                        if (i.answerText === "No") {
                          return true
                        }
                      }
                    })

                    myresp.forEach((i) => {
                      return statusArray.push(i.questionId)
                    })

                    let firstUnansweredQuestion;

                    const abeeType = window.localStorage.getItem("abeeType");
                    const grade = window.localStorage.getItem("grade");
                    let skipOrder = abeeQuestionOrder(abeeType, grade, true)
                    let noSkipOrder = abeeQuestionOrder(abeeType, grade)

                    if (skip) {
                      firstUnansweredQuestion = skipOrder.filter((i) => {
                        return statusArray.indexOf(i) < 0;
                      })
                    } else {
                      firstUnansweredQuestion = noSkipOrder.filter((i) => {
                        return statusArray.indexOf(i) < 0;
                      })
                    }

                    if (!firstUnansweredQuestion[0]) {
                      return navigate(`/background/${firstQuestionId}`);
                    } else {
                      // literacy question
                      if (firstUnansweredQuestion[0][0] === 'l') {
                        return navigate(`/literacy/${firstUnansweredQuestion[0]}`);
                        // numeracy question
                      } else if (firstUnansweredQuestion[0][0] === 'n') {
                        return navigate(`/numeracy/${firstUnansweredQuestion[0]}`);
                        //temp - WPM question
                      } else if (firstUnansweredQuestion[0] === 'WPM') {
                        return navigate(`/WPM`);
                      }
                      return navigate(`/background/${firstUnansweredQuestion[0]}`);
                    }

                  } else {
                    return navigate(`/background/${firstQuestionId}`);
                  }
                })
                .then(() => {
                  setLoading(false)
                })
                .catch(err => {
                  console.error("Cannot sign in.", err.message);
                  if (err.message === "This platform is only for student and caregiver accounts.") {
                    toast.error(
                      "This platform is only for student and caregiver accounts."
                    );
                  } else {
                    toast.error(
                      `Incorrect username or password.`
                    );
                  }
                  //ending the session
                  window.localStorage.clear();
                  navigate(`/`);
                });
            }}
          >
            <Typography noWrap style={{
              fontSize: 20, letterSpacing: "0.1em", background: "-webkit-linear-gradient(45deg, #e6eefc 30%, #d9e6fc 90%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontFamily: "American Typewriter, serif"
            }}>
              Sign In
            </Typography>
          </Button>
          {/* Commenting out because integration not supported in prod yet */}
          {/* <div>
          <a href={`https://clever.com/oauth/authorize?response_type=code&redirect_uri=https://app.inlierlearning.com/cleveroauth&client_id=e75aeaf562550034a139&state=${stateParam}`}>
            <img src={CleverButton} alt="Clever login button" />
          </a>
        </div> */}
          <div className={classes.loginHelpLinks}>
            <Link href="https://www.inlierlearning.com/requestapphelp" target="_blank" rel="noreferrer" >
              Request Help
            </Link>
            <div>
              <Link href="/passwordreset" rel="noreferrer" >
                Reset Password
              </Link>
            </div>
          </div>
        </Paper>
      </Grid >
    </div>
  );
};
