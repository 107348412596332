import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { App } from "./App";

const theme = createTheme({
  palette: {
    background: {
      default: "#E5E8E9",
    },
    primary: {
      //main blue
      main: "#1876D0",
    },
    secondary: {
      //main blue
      main: "#1876D0"
    }
  }
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.getElementById("root")
);
