// text for all questions and their options
export const backgroundQuestionTextObj = {
    "59209S": {
        Arabic: "",
        Bengali: "",
        English: "Which of the following areas are you interested in? Please check all that apply.",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "¿Cuál de las siguientes áreas te interesa? Por favor marca todos los que aplican.",
        Vietnamese: "",
        Mam: ""
    },
    "59209C": {
        Arabic: "",
        Bengali: "",
        English: "Which of the following areas is your student interested in? Please check all that apply.",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "¿Cuál de las siguientes áreas le interesa a tu estudiante? Por favor marca todos los que aplican.",
        Vietnamese: "",
        Mam: ""
    },
    "59209a": {
        Arabic: "",
        Bengali: "",
        English: "Music",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Música",
        Vietnamese: "",
        Mam: ""
    },
    "59209b": {
        Arabic: "",
        Bengali: "",
        English: "Technology",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Tecnología",
        Vietnamese: "",
        Mam: ""
    },
    "59209c": {
        Arabic: "",
        Bengali: "",
        English: "Cars",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Carros",
        Vietnamese: "",
        Mam: ""
    },
    "59209d": {
        Arabic: "",
        Bengali: "",
        English: "Art",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Arte",
        Vietnamese: "",
        Mam: ""
    },
    "59209e": {
        Arabic: "",
        Bengali: "",
        English: "Science / Math",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Ciencia / Matemáticas",
        Vietnamese: "",
        Mam: ""
    },
    "59209f": {
        Arabic: "",
        Bengali: "",
        English: "Dance",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Bailar",
        Vietnamese: "",
        Mam: ""
    },
    "59209g": {
        Arabic: "",
        Bengali: "",
        English: "Reading",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Lectura",
        Vietnamese: "",
        Mam: ""
    },
    "59209h": {
        Arabic: "",
        Bengali: "",
        English: "Gardening/Outdoors",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Jardinería / Estar al aire libre",
        Vietnamese: "",
        Mam: ""
    },
    "59209i": {
        Arabic: "",
        Bengali: "",
        English: "Sports",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Deportes",
        Vietnamese: "",
        Mam: ""
    },
    "59209j": {
        Arabic: "",
        Bengali: "",
        English: "Gaming",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Juegos de vídeo",
        Vietnamese: "",
        Mam: ""
    },
    "59209k": {
        Arabic: "",
        Bengali: "",
        English: "Movies/TV",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Películas / televisión",
        Vietnamese: "",
        Mam: ""
    },
    "59209l": {
        Arabic: "",
        Bengali: "",
        English: "Food",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Comida",
        Vietnamese: "",
        Mam: ""
    },
    "59209m": {
        Arabic: "",
        Bengali: "",
        English: "Other",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Otra cosa",
        Vietnamese: "",
        Mam: ""
    },
    "1S": {
        Arabic: "هل انت من مدينه",
        Bengali: "আপনি কি শহর থেকে এসেছেন?",
        English: "Are you from a city?",
        French: "Vous venez d'une ville?",
        Portuguese: "Vc e de uma cidade",
        Russian: "Ты из города?",
        Spanish: "¿Eres de una ciudad?",
        Vietnamese: "Bạn đến từ một thành phố?",
        Mam: ""
    },
    "1C": {
        Arabic: "هل تلميذك من مدينة؟",
        Bengali: "আপনার ছাত্র কি শহর থেকে এসেছে?",
        English: "Is your student from a city?",
        French: "Votre étudiant vient d'une ville?",
        Portuguese: "Seu aluno é de uma cidade?",
        Russian: "Ваш студент из города?",
        Spanish: "¿Tu estudiante es de una ciudad?",
        Vietnamese: "Là sinh viên của bạn đến từ một thành phố?",
        Mam: ""
    },
    "2S": {
        Arabic: "",
        Bengali: "",
        English: "Were there times you could not attend school?",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "¿Hubo ocasiones en las que no pudiste asistir a la escuela?",
        Vietnamese: "",
        Mam: ""
    },
    "2C": {
        Arabic: "",
        Bengali: "",
        English: "Were there times your student could not attend school?",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish:
            "¿Hubo ocasiones en las que tu estudiante no pudo asistir a la escuela?",
        Vietnamese: "",
        Mam: ""
    },
    "2a": {
        Arabic: "",
        Bengali: "",
        English: "Yes, during COVID",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Sí, durante COVID",
        Vietnamese: "",
        Mam: ""
    },
    "2b": {
        Arabic: "",
        Bengali: "",
        English: "Yes, not during COVID",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Sí, no durante COVID",
        Vietnamese: "",
        Mam: ""
    },
    "2BS": {
        Arabic: "",
        Bengali: "",
        English:
            "On the occasions you missed school, did you miss a total of 2 years or more?",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish:
            "En las ocasiones en que faltaste a la escuela, ¿perdiste un total de 2 años o más?",
        Vietnamese: "",
        Mam: ""
    },
    "2BC": {
        Arabic: "",
        Bengali: "",
        English:
            "On the occasions your student missed school, did they miss a total of 2 years or more?",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish:
            "En las ocasiones en que tu estudiante faltó a la escuela, ¿faltó un total de 2 años o más?",
        Vietnamese: "",
        Mam: ""
    },
    "3S": {
        Arabic: "لماذا لا تستطيع الذهاب إلى المدرسة؟ يرجى التحقق من كل ما ينطبق.",
        Bengali: "আপনি কেন স্কুলে যেতে পারেননি ? প্রযোজ্য সব চেক করুন।",
        English: "Why couldn't you attend school? Please check all that apply.",
        French:
            "Pourquoi ne pouvais-tu pas aller à l'école ? Veuillez cocher tout ce qui s'applique.",
        Portuguese:
            "Por que você não pôde frequentar a escola? Marque todas as opções aplicáveis.",
        Russian:
            "Почему ты не мог пойти в школу? Пожалуйста, отметьте все, что относится.",
        Spanish:
            "¿Por qué no pudiste asistir a la escuela? Por favor marca todos los que aplican.",
        Vietnamese:
            "Tại sao bạn không thể đi học? Vui lòng kiểm tra tất cả những gì áp dụng.",
        Mam: ""
    },
    "3C": {
        Arabic:
            "لماذا لا يستطيع تلميذك الذهاب إلى المدرسة؟ يرجى التحقق من كل ما ينطبق.",
        Bengali: "আপনার ছাত্র স্কুলে যেতে পারেনি কেন? প্রযোজ্য সব চেক করুন।",
        English:
            "Why couldn't your student attend school? Please check all that apply.",
        French:
            "Pourquoi votre élève n'a-t-il pas pu aller à l'école ? Veuillez cocher tout ce qui s'applique.",
        Portuguese:
            "Por que seu aluno não pôde frequentar a escola? Marque todas as opções aplicáveis.",
        Russian:
            "Почему ваш ученик не мог посещать школу? Пожалуйста, отметьте все, что относится.",
        Spanish:
            "¿Por qué tu estudiante no pudo asistir a la escuela? Por favor marca todos los que aplican.",
        Vietnamese:
            "Tại sao học sinh của bạn không thể đi học? Vui lòng kiểm tra tất cả những gì áp dụng.",
        Mam: ""
    },
    "3aS": {
        Arabic: "كانت المدرسة باهظة الثمن",
        Bengali: "স্কুলে যেতে বেশি টাকা লাগতো এজন্য",
        English: "School was too expensive",
        French: "L'école était trop chère",
        Portuguese: "Escola era muito cara",
        Russian: "Школа была слишком дорогой",
        Spanish: "La escuela era demasiado cara",
        Vietnamese: "Trường học quá đắt",
        Mam: ""
    },
    "3aC": {
        Arabic: "كانت المدرسة باهظة الثمن",
        Bengali: "স্কুলে যেতে বেশি টাকা লাগতো এজন্য",
        English: "School was too expensive",
        French: "L'école était trop chère",
        Portuguese: "Escola era muito cara",
        Russian: "Школа была слишком дорогой",
        Spanish: "La escuela era demasiado cara",
        Vietnamese: "Trường học quá đắt",
        Mam: ""
    },
    "3bS": {
        Arabic: "لم يسمح لي بالذهاب إلى المدرسة",
        Bengali: "স্কুলে যেতে বেশি টাকা লাগতো এজন্য",
        English: "I was not allowed to go to school",
        French: "je n'avais pas le droit d'aller à l'école",
        Portuguese: "Eu não tinha permissão para ir à escola",
        Russian: "Меня не пустили в школу",
        Spanish: "No se me permitió ir a la escuela",
        Vietnamese: "Tôi không được phép đi học",
        Mam: ""
    },
    "3bC": {
        Arabic: "لم يسمح لطالبتي بالذهاب إلى المدرسة",
        Bengali: "আমার ছাত্রকে স্কুলে যেতে দেওয়া হয়নি",
        English: "My student was not allowed to go to school",
        French: "Mon élève n'a pas été autorisé à aller à l'école",
        Portuguese: "Meu aluno não foi autorizado a ir à escola",
        Russian: "Моему ученику не разрешили ходить в школу",
        Spanish: "A mi estudiante no se le permitió ir a la escuela",
        Vietnamese: "Học sinh của tôi không được phép đến trường",
        Mam: ""
    },
    "3cS": {
        Arabic: "لم يكن لدي وسيلة مواصلات",
        Bengali: "আমার যাওয়ার কোন ব্যবস্থা ছিলনা",
        English: "I had no transportation",
        French: "je n'avais pas de moyen de transport",
        Portuguese: "Eu não tinha transporte",
        Russian: "У меня не было транспорта",
        Spanish: "No tenía transporte",
        Vietnamese: "Tôi không có phương tiện đi lại",
        Mam: ""
    },
    "3cC": {
        Arabic: "لم يكن لدى تلميذي وسيلة مواصلات",
        Bengali: "আমার ছাত্র স্কুলে যাওয়ার জন্য কোন ব্যবস্থা ছিলনা",
        English: "My student had no transportation",
        French: "Mon élève n'avait pas de moyen de transport",
        Portuguese: "Meu aluno não tinha transporte",
        Russian: "У моего ученика не было транспорта",
        Spanish: "Mi estudiante no tenía transporte",
        Vietnamese: "Học sinh của tôi không có phương tiện đi lại",
        Mam: ""
    },
    "3dS": {
        Arabic: "كان علي أن أعمل",
        Bengali: "আমাকে কাজ করতে হয়েছিল",
        English: "I had to work",
        French: "je devais travailler",
        Portuguese: "eu tive que trabalhar",
        Russian: "я должен был работать",
        Spanish: "Tenía que trabajar",
        Vietnamese: "Tôi phải làm việc",
        Mam: ""
    },

    "3dC": {
        Arabic: "كان على تلميذي العمل",
        Bengali: "আমার ছাত্রকে কাজ করতে হয়েছিল",
        English: "My student had to work",
        French: "Mon élève a dû travailler",
        Portuguese: "Meu aluno teve que trabalhar",
        Russian: "Моему ученику пришлось работать",
        Spanish: "Mi estudiante tuvo que trabajar",
        Vietnamese: "Sinh viên của tôi phải làm việc",
        Mam: ""
    },
    "3eS": {
        Arabic: "كان علي مساعدة عائلتي",
        Bengali: "আমাকে আমার পরিবারকে সাহায্য করতে হয়েছিল",
        English: "I had to help my family",
        French: "J'ai dû aider ma famille",
        Portuguese: "Eu tive que ajudar minha familia",
        Russian: "Я должен был помочь своей семье",
        Spanish: "Tuve que ayudar a mi familia",
        Vietnamese: "Tôi đã phải giúp đỡ gia đình của tôi",
        Mam: ""
    },
    "3eC": {
        Arabic: "كان على تلميذي مساعدة أسرهم",
        Bengali: "আমার ছাত্রকে তাদের পরিবারকে সাহায্য করতে হয়েছিল",
        English: "My student had to help their family",
        French: "Mon élève a dû aider sa famille",
        Portuguese: "Meu aluno teve que ajudar sua família",
        Russian: "Моему ученику пришлось помогать своей семье",
        Spanish: "Mi estudiante tuvo que ayudar a su familia",
        Vietnamese: "Sinh viên của tôi đã phải giúp đỡ gia đình của họ",
        Mam: ""
    },
    "3fS": {
        Arabic: "لقد كنت مريضا",
        Bengali: "আমি অসুস্থ ছিলাম",
        English: "I was ill or a family member was ill",
        French: "j'étais malade",
        Portuguese: "eu estava doente",
        Russian: "я был болен",
        Spanish: "Yo estaba enfermo o alguien de mi familia estaba enfermo",
        Vietnamese: "Tôi bị ốm",
        Mam: ""
    },
    "3fC": {
        Arabic: "كان تلميذي مريضا",
        Bengali: "আমার ছাত্র অসুস্থ ছিল",
        English: "My student was ill or a family member was ill",
        French: "Mon élève était malade",
        Portuguese: "Meu aluno estava doente",
        Russian: "Мой ученик заболел",
        Spanish: "Mi estudiante estaba enfermo o alguien de mi familia estaba enfermo",
        Vietnamese: "Sinh viên của tôi bị ốm",
        Mam: ""
    },
    "3gS": {
        Arabic: "",
        Bengali: "",
        English: "I was forced to leave my home",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Me obligaron a dejar mi casa",
        Vietnamese: "",
        Mam: ""
    },
    "3gC": {
        Arabic: "",
        Bengali: "",
        English: "My student was forced to leave their home",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Obligaron a mi estudiante a dejar su casa",
        Vietnamese: "",
        Mam: ""
    },
    "3hS": {
        Arabic: "",
        Bengali: "",
        English: "School did not let me enroll",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "La escuela no me permitió inscribirme",
        Vietnamese: "",
        Mam: ""
    },
    "3hC": {
        Arabic: "",
        Bengali: "",
        English: "School did not let my student enroll",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "La escuela no permitió que mi estudiante se inscribiera",
        Vietnamese: "",
        Mam: ""
    },
    "3iS": {
        Arabic: "",
        Bengali: "",
        English: "Other reason",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Otra razon",
        Vietnamese: "",
        Mam: ""
    },
    "3iC": {
        Arabic: "",
        Bengali: "",
        English: "Other reason",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Otra razon",
        Vietnamese: "",
        Mam: ""
    },
    "4S": {
        Arabic: "كم كان عمرك عندما بدأت بالذهاب إلى المدرسة؟",
        Bengali: "আপনি যখন স্কুলে যেতে শুরু করেছিলেন তখন আপনার বয়স কত ছিল?",
        English: "How old were you when you started going to school?",
        French: "Quel âge aviez-vous lorsque vous avez commencé à aller à l'école ?",
        Portuguese: "Quantos anos você tinha quando começou a ir para a escola?",
        Russian: "Сколько вам было лет, когда вы пошли в школу?",
        Spanish: "¿Qué edad tenías cuando empezaste a ir a la escuela?",
        Vietnamese: "Bạn bắt đầu đi học bao nhiêu tuổi?",
        Mam: ""
    },
    "4C": {
        Arabic: "كم كان عمر تلميذك عندما بدأ الذهاب إلى المدرسة؟",
        Bengali: "আপনার ছাত্র যখন স্কুলে যেতে শুরু করেছিল তখন তার বয়স কত ছিল?",
        English: "How old was your student when they started going to school?",
        French: "Quel âge avait votre élève lorsqu'il a commencé à aller à l'école ?",
        Portuguese: "Quantos anos seu aluno tinha quando começou a ir para a escola?",
        Russian: "Сколько лет было вашему ученику, когда они пошли в школу?",
        Spanish: "¿Qué edad tenía tu estudiante cuando empezó a ir a la escuela?",
        Vietnamese: "Học sinh của bạn bao nhiêu tuổi khi chúng bắt đầu đi học?",
        Mam: ""
    },
    "5S": {
        Arabic: "كم كان عمرك عندما توقفت عن الذهاب إلى المدرسة؟",
        Bengali: "আপনি যখন স্কুলে যাওয়া বন্ধ করেছিলেন তখন আপনার বয়স কত ছিল?",
        English: "How old were you when you stopped going to school?",
        French: "Quel âge aviez-vous lorsque vous avez arrêté d'aller à l'école ?",
        Portuguese: "Quantos anos você tinha quando parou de ir à escola?",
        Russian: "Сколько вам было лет, когда вы перестали ходить в школу?",
        Spanish: "¿Qué edad tenías cuando dejaste de ir a la escuela?",
        Vietnamese: "Bạn bao nhiêu tuổi khi bạn ngừng đi học?",
        Mam: ""
    },
    "5C": {
        Arabic: "كم كان عمر الطالب الخاص بك عندما توقف عن الذهاب إلى المدرسة؟",
        Bengali: "আপনার ছাত্রের বয়স কত ছিল যখন সে স্কুলে যাওয়া বন্ধ করেছিল?",
        English: "How old was your student when they stopped going to school?",
        French: "Quel âge avait votre élève lorsqu'il a arrêté d'aller à l'école ?",
        Portuguese: "Quantos anos seu aluno tinha quando parou de ir à escola?",
        Russian:
            "Сколько лет было вашему ученику, когда они перестали ходить в школу?",
        Spanish: "¿Qué edad tenía tu estudiante cuando dejó de ir a la escuela?",
        Vietnamese: "Học sinh của bạn bao nhiêu tuổi khi chúng ngừng đi học?",
        Mam: ""
    },
    "6S": {
        Arabic: "هل كان معظم تعليمك المدرسي باللغة العربية؟",
        Bengali: "আপনার স্কুলের অধিকাংশ শিক্ষা কি বাংলায় ছিল?",
        English: "Was most of your school instruction in English?",
        French: "La plupart de votre instruction scolaire était-elle en français?",
        Portuguese: "A maior parte da instrução da sua escola foi em português?",
        Russian: "Большая часть ваших школьных занятий велась на русском языке?",
        Spanish: "¿La mayor parte de tu instrucción escolar fue en español?",
        Vietnamese: "Hầu hết các bài giảng ở trường của bạn đều bằng tiếng Việt?",
        Mam: ""
    },
    "6C": {
        Arabic: "هل كان معظم تعليم الطالب المدرسي باللغة العربية؟",
        Bengali: "আপনার শিক্ষার্থীর স্কুলের অধিকাংশ শিক্ষা কি বাংলায় ছিল?",
        English: "Was most of your student's school instruction in English?",
        French:
            "La majeure partie de l'enseignement scolaire de votre élève était-elle en français?",
        Portuguese:
            "A maior parte da instrução escolar do seu aluno foi em português?",
        Russian:
            "Была ли большая часть школьных инструкций вашего ученика на русском языке?",
        Spanish:
            "¿La mayor parte de la instrucción escolar de tu estudiante fue en español?",
        Vietnamese: "Hầu hết học sinh của bạn giảng dạy bằng tiếng Việt?",
        Mam: ""
    },
    "7S": {
        Arabic: "ما هو آخر صف أكملته في المدرسة؟",
        Bengali: "তুমি সর্বশেষ কোন ক্লাসে পড়াশুনা করেছিলে?",
        English: "What is the last grade you completed in school?",
        French: "Quelle est la dernière année que vous avez terminée à l'école?",
        Portuguese: "Qual é a última série que você concluiu na escola?",
        Russian: "Какой последний класс вы закончили в школе?",
        Spanish: "¿Cuál es el último grado que completaste en la escuela?",
        Vietnamese: "Bạn đã hoàn thành lớp cuối cùng ở trường là bao nhiêu?",
        Mam: ""
    },
    "7C": {
        Arabic: "ما هو آخر صف أكمله الطالب في المدرسة؟",
        Bengali: "তোমার ছাত্র সর্বশেষ কোন ক্লাসে পড়াশোনা করেছিলো?",
        English: "What is the last grade your student completed in school?",
        French: "Quelle est la dernière année que votre élève a terminée à l'école ?",
        Portuguese: "Qual é a última série que seu aluno concluiu na escola?",
        Russian: "Какой последний класс закончил ваш ученик в школе?",
        Spanish: "¿Cuál es el último grado que completó tu estudiante en la escuela?",
        Vietnamese:
            "Lớp cuối cùng mà học sinh của bạn hoàn thành ở trường là bao nhiêu?",
        Mam: ""
    },
    "8S": {
        Arabic: "ماذا كانت بيئة مدرستك؟ يرجى التحقق من كل ما ينطبق.",
        Bengali: "আপনার স্কুলের পরিবেশ কেমন ছিল? প্রযোজ্য সব চেক করুন।",
        English: "What was your school environment? Please check all that apply.",
        French:
            "Quel était votre environnement scolaire ? Veuillez cocher tout ce qui s'applique.",
        Portuguese:
            "Qual foi o seu ambiente escolar? Marque todas as opções aplicáveis.",
        Russian:
            "Какой была ваша школьная среда? Пожалуйста, отметьте все, что относится.",
        Spanish:
            "¿Cuál fue tu ambiente escolar? Por favor marca todos los que aplican.",
        Vietnamese:
            "Môi trường học của bạn là gì? Vui lòng kiểm tra tất cả những gì áp dụng.",
        Mam: ""
    },
    "8C": {
        Arabic: "ماذا كانت البيئة المدرسية لتلميذك؟ يرجى التحقق من كل ما ينطبق.",
        Bengali: "আপনার শিক্ষার্থীর স্কুলের পরিবেশ কেমন ছিল? প্রযোজ্য সব চেক করুন।",
        English:
            "What was your student's school environment? Please check all that apply.",
        French:
            "Quel était l'environnement scolaire de votre élève ? Veuillez cocher tout ce qui s'applique.",
        Portuguese:
            "Qual era o ambiente escolar do seu aluno? Marque todas as opções aplicáveis.",
        Russian:
            "Какой была школьная среда вашего ученика? Пожалуйста, отметьте все, что относится.",
        Spanish:
            "¿Cuál fue el ambiente escolar de tu estudiante? Por favor marca todos los que aplican.",
        Vietnamese:
            "Môi trường học của học sinh của bạn là gì? Vui lòng kiểm tra tất cả những gì áp dụng.",
        Mam: ""
    },
    "8aS": {
        Arabic: "صفي كان لديه فتيات فقط",
        Bengali: "আমার ক্লাসে শুধু মেয়েরা ছিল",
        English: "My class had only girls",
        French: "Ma classe n'avait que des filles",
        Portuguese: "Minha classe tinha apenas meninas",
        Russian: "В моем классе были только девочки",
        Spanish: "Mi clase solo tenía niñas",
        Vietnamese: "Lớp tôi chỉ có toàn nữ",
        Mam: ""
    },
    "8aC": {
        Arabic: "فصل تلميذي كان به فتيات فقط",
        Bengali: "আমার ছাত্রের ক্লাসে শুধু মেয়েরা ছিল",
        English: "My student's class had only girls",
        French: "La classe de mon élève n'avait que des filles",
        Portuguese: "A classe do meu aluno tinha apenas meninas",
        Russian: "В моем студенческом классе были только девочки",
        Spanish: "La clase de mi estudiante solo tenía niñas.",
        Vietnamese: "Lớp học sinh của tôi chỉ có toàn nữ",
        Mam: ""
    },
    "8bS": {
        Arabic: "صفي كان لديه أولاد فقط",
        Bengali: "আমার ক্লাসে শুধু ছেলেরা ছিল",
        English: "My class had only boys",
        French: "Ma classe n'avait que des garçons",
        Portuguese: "Minha classe tinha apenas meninos",
        Russian: "В моем классе были только мальчики",
        Spanish: "Mi clase solo tenía niños",
        Vietnamese: "Lớp tôi chỉ có con trai",
        Mam: ""
    },
    "8bC": {
        Arabic: "فصل تلميذي كان فيه أولاد فقط",
        Bengali: "আমার ছাত্রের ক্লাসে শুধু ছেলেরা ছিল",
        English: "My student's class had only boys",
        French: "La classe de mon élève n'avait que des garçons",
        Portuguese: "A classe do meu aluno tinha apenas meninos",
        Russian: "В классе моего ученика были только мальчики",
        Spanish: "La clase de mi estudiante solo tenía niños.",
        Vietnamese: "Lớp học của học sinh tôi chỉ có con trai",
        Mam: ""
    },
    "8cS": {
        Arabic: "كثيرا ما كان أساتذتي غائبين أو متأخرين",
        Bengali: "আমার শিক্ষকরা প্রায়ই অনুপস্থিত ছিলেন বা দেরিতে ছিলেন",
        English: "My teachers were frequently absent or late",
        French: "Mes professeurs étaient souvent absents ou en retard",
        Portuguese: "Meus professores faltavam com frequência ou atrasavam",
        Russian: "Мои учителя часто отсутствовали или опаздывали",
        Spanish: "Mis maestros se ausentaban con frecuencia o llegaban tarde",
        Vietnamese: "Giáo viên của tôi thường xuyên vắng mặt hoặc đến muộn",
        Mam: ""
    },
    "8cC": {
        Arabic: "غالبًا ما يتغيب معلمو تلميذي أو يتأخرون",
        Bengali: "আমার ছাত্রের শিক্ষকরা প্রায়ই অনুপস্থিত ছিলেন বা দেরিতে ছিলেন",
        English: "My student's teachers were frequently absent or late",
        French: "Les professeurs de mes élèves étaient souvent absents ou en retard",
        Portuguese:
            "Os professores do meu aluno faltavam com frequência ou atrasavam",
        Russian: "Учителя моих учеников часто отсутствовали или опаздывали",
        Spanish:
            "Los maestros de mi estudiante se ausentaban con frecuencia o llegaban tarde",
        Vietnamese:
            "Giáo viên của học sinh của tôi thường xuyên vắng mặt hoặc đến muộn",
        Mam: ""
    },
    "8dS": {
        Arabic: "كان حجم فصلي صغيرًا",
        Bengali: "আমার ক্লাসের সাইজ ছোট ছিল",
        English: "My class size was small",
        French: "La taille de ma classe était petite",
        Portuguese: "Minha turma era pequena",
        Russian: "Размер моего класса был маленьким",
        Spanish: "El tamaño de mi clase era pequeño",
        Vietnamese: "Quy mô lớp học của tôi nhỏ",
        Mam: ""
    },
    "8dC": {
        Arabic: "كان حجم فصل الطالب صغيرًا",
        Bengali: "আমার ছাত্রের ক্লাস সাইজ ছোট ছিল",
        English: "My student's class size was small",
        French: "La taille de la classe de mon élève était petite",
        Portuguese: "O tamanho da classe do meu aluno era pequeno",
        Russian: "Размер класса моего ученика был маленьким",
        Spanish: "El tamaño de la clase de mi estudiante era pequeño",
        Vietnamese: "Quy mô lớp học của học sinh của tôi nhỏ",
        Mam: ""
    },
    "8eS": {
        Arabic: "كان حجم صفي كبيرًا",
        Bengali: "আমার ক্লাসের সাইজ বড় ছিল",
        English: "My class size was large",
        French: "La taille de ma classe était grande",
        Portuguese: "O tamanho da minha turma era grande",
        Russian: "Размер моего класса был большим",
        Spanish: "El tamaño de mi clase era grande",
        Vietnamese: "Quy mô lớp học của tôi lớn",
        Mam: ""
    },
    "8eC": {
        Arabic: "كان حجم الفصل الدراسي لطالبتي كبيرًا",
        Bengali: "আমার ছাত্রের ক্লাসের সাইজ বড় ছিল",
        English: "My student's class size was large",
        French: "La taille de la classe de mon élève était grande",
        Portuguese: "O tamanho da classe do meu aluno era grande",
        Russian: "Размер класса моего студента был большим",
        Spanish: "El tamaño de la clase de mi estudiante era grande",
        Vietnamese: "Quy mô lớp học của học sinh của tôi rất lớn",
        Mam: ""
    },
    "8fS": {
        Arabic: "لم يكن لدينا كتب أو أقلام رصاص متاحة",
        Bengali: "আমাদের কাছে বই বা পেন্সিল ছিল না",
        English: "We did not have books or pencils available",
        French: "Nous n'avions pas de livres ou de crayons disponibles",
        Portuguese: "Não tínhamos livros ou lápis disponíveis",
        Russian: "У нас не было книг или карандашей",
        Spanish: "No teníamos libros ni lápices disponibles",
        Vietnamese: "Chúng tôi không có sẵn sách hoặc bút chì",
        Mam: ""
    },
    "8fC": {
        Arabic: "لم يكن لدى تلميذي كتب أو أقلام رصاص متاحة",
        Bengali: "আমার ছাত্রের কাছে বই বা পেন্সিল পাওয়া যায় নি",
        English: "My student did not have books or pencils available",
        French: "Mon élève n'avait pas de livres ou de crayons à disposition",
        Portuguese: "Meu aluno não tinha livros ou lápis disponíveis",
        Russian: "У моего ученика не было книг или карандашей",
        Spanish: "Mi estudiante no tenía libros ni lápices disponibles",
        Vietnamese: "Học sinh của tôi không có sẵn sách hoặc bút chì",
        Mam: ""
    },
    "8gS": {
        Arabic: "كانت لدينا أجهزة كمبيوتر متاحة",
        Bengali: "আমাদের স্কুলে কম্পিউটার ছিল",
        English: "We had computers available",
        French: "Nous avions des ordinateurs disponibles",
        Portuguese: "Tínhamos computadores disponíveis",
        Russian: "У нас были компьютеры в наличии",
        Spanish: "Teníamos computadoras disponibles",
        Vietnamese: "Chúng tôi đã có sẵn máy tính",
        Mam: ""
    },
    "8gC": {
        Arabic: "كان لدى تلميذي أجهزة كمبيوتر متاحة",
        Bengali: "আমাদের স্কুলে কম্পিউটার ছিল ছাত্রদের জন্য",
        English: "My student had computers available",
        French: "Mon élève disposait d'ordinateurs",
        Portuguese: "Meu aluno tinha computadores disponíveis",
        Russian: "У моего ученика были компьютеры",
        Spanish: "Mi estudiante tenía computadoras disponibles",
        Vietnamese: "Học sinh của tôi có sẵn máy tính",
        Mam: ""
    },
    "8hS": {
        Arabic: "",
        Bengali: "",
        English: "We only studied religious text",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Solo estudiamos textos religiosos",
        Vietnamese: "",
        Mam: ""
    },
    "8hC": {
        Arabic: "",
        Bengali: "",
        English: "My student only studied religious text",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Mi estudiante estudió solamente textos religiosos",
        Vietnamese: "",
        Mam: ""
    },
    "8iS": {
        Arabic: "",
        Bengali: "",
        English: "There were students of many different ages in my class",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Había estudiantes de muchas edades diferentes en mi clase",
        Vietnamese: "",
        Mam: ""
    },
    "8iC": {
        Arabic: "",
        Bengali: "",
        English: "There were students of many different ages in my student's class",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish:
            "Había estudiantes de muchas edades diferentes en la clase de mi estudiante",
        Vietnamese: "",
        Mam: ""
    },
    "8jS": {
        Arabic: "",
        Bengali: "",
        English: "My class had both boys and girls",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Mi clase tenía niños y niñas",
        Vietnamese: "",
        Mam: ""
    },
    "8jC": {
        Arabic: "",
        Bengali: "",
        English: "My student's class had both boys and girls",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "La clase de mi estudiante tenía niños y niñas",
        Vietnamese: "",
        Mam: ""
    },
    "9S": {
        Arabic: "كيف كانت تجربة مدرستك مثل؟ يرجى التحقق من كل ما ينطبق.",
        Bengali: "আপনার স্কুলের অভিজ্ঞতা কেমন ছিল? প্রযোজ্য সব চেক করুন।",
        English: "What was your school experience like? Please check all that apply.",
        French:
            "Quelle a été votre expérience scolaire ? Veuillez cocher tout ce qui s'applique.",
        Portuguese:
            "Como foi sua experiência escolar? Marque todas as opções aplicáveis.",
        Russian:
            "Каким был твой школьный опыт? Пожалуйста, отметьте все, что относится.",
        Spanish:
            "¿Cómo fue tu experiencia escolar? Por favor marca todos los que aplican.",
        Vietnamese:
            "Trải nghiệm ở trường của bạn như thế nào? Vui lòng kiểm tra tất cả những gì áp dụng.",
        Mam: ""
    },
    "9C": {
        Arabic: "كيف كانت تجربة الطالب في المدرسة مثل؟ يرجى التحقق من كل ما ينطبق.",
        Bengali: "আপনার ছাত্রের স্কুলের অভিজ্ঞতা কেমন ছিল? প্রযোজ্য সব চেক করুন।",
        English:
            "What was your student's school experience like? Please check all that apply.",
        French:
            "Quelle a été l'expérience scolaire de votre élève? Veuillez cocher tout ce qui s'applique.",
        Portuguese:
            "Como foi a experiência escolar do seu aluno? Marque todas as opções aplicáveis.",
        Russian:
            "Каким был школьный опыт вашего ученика? Пожалуйста, отметьте все, что относится.",
        Spanish:
            "¿Cómo fue la experiencia escolar de tu estudiante? Por favor marca todos los que aplican.",
        Vietnamese:
            "Học sinh của bạn trải nghiệm như thế nào? Vui lòng kiểm tra tất cả những gì áp dụng.",
        Mam: ""
    },
    "9aS": {
        Arabic: "كان يومي المدرسي أقل من 4 ساعات",
        Bengali: "আমাদের স্কুল প্রতিদিন ৪ ঘন্টা বা তার কম ছিল",
        English: "My school day was less than 4 hours long",
        French: "Ma journée d'école durait moins de 4 heures",
        Portuguese: "Meu dia na escola durava menos de 4 horas",
        Russian: "Мой школьный день длился менее 4 часов",
        Spanish: "Mi día escolar duraba menos de 4 horas",
        Vietnamese: "Một ngày học của tôi chỉ dài chưa đầy 4 giờ",
        Mam: ""
    },
    "9aC": {
        Arabic: "كان اليوم الدراسي لطالبتي أقل من 4 ساعات",
        Bengali: "আমাদের স্কুল প্রতিদিন ৪ ঘন্টা বা তার কম ছিল ছাত্রদের জন্য",
        English: "My student's school day was less than 4 hours long",
        French: "La journée d'école de mon élève durait moins de 4 heures",
        Portuguese: "O dia escolar do meu aluno durava menos de 4 horas",
        Russian: "Учебный день моей ученицы длился менее 4 часов",
        Spanish: "El día escolar de mi estudiante duraba menos de 4 horas",
        Vietnamese: "Một ngày học của học sinh tôi chỉ dài chưa đầy 4 giờ",
        Mam: ""
    },
    "9bS": {
        Arabic: "كررت سنة دراسية واحدة أو أكثر",
        Bengali: "একই ক্লাসে আমি এক বছর বা তার বেশি পড়েছি",
        English: "I repeated one or more school years",
        French: "J'ai redoublé une ou plusieurs années scolaires",
        Portuguese: "Repeti um ou mais anos escolares",
        Russian: "Я повторил один или несколько школьных лет",
        Spanish: "Repetí uno o más años escolares",
        Vietnamese: "Tôi đã lặp lại một hoặc nhiều năm học",
        Mam: ""
    },
    "9bC": {
        Arabic: "أعاد تلميذي سنة دراسية واحدة أو أكثر",
        Bengali: "আমার ছাত্র একই ক্লাসে এক বছর বা তার বেশি পড়েছে",
        English: "My student repeated one or more school years",
        French: "Mon élève a redoublé une ou plusieurs années scolaires",
        Portuguese: "Meu aluno repetiu um ou mais anos letivos",
        Russian: "Мой ученик повторил один или несколько школьных лет",
        Spanish: "Mi estudiante repitió uno o más años escolares",
        Vietnamese: "Học sinh của tôi lặp lại một hoặc nhiều năm học",
        Mam: ""
    },
    "9cS": {
        Arabic:
            "كثيرا ما اضطررت إلى ترك المدرسة في وقت مبكر أو الذهاب إلى المدرسة في وقت متأخر",
        Bengali:
            "আমাকে প্রায়ই তাড়াতাড়ি স্কুল ছাড়তে হতো বা দেরিতে স্কুলে যেতে হতো।",
        English: "I frequently had to leave school early or went to school late",
        French: "J'ai souvent dû quitter l'école tôt ou aller à l'école tard",
        Portuguese:
            "Freqüentemente, eu tinha que sair da escola mais cedo ou ia para a escola tarde",
        Russian:
            "Мне часто приходилось рано уходить из школы или поздно приходить в школу",
        Spanish:
            "Con frecuencia tenía que salir temprano de la escuela o llegar tarde a la escuela",
        Vietnamese: "Tôi thường xuyên phải nghỉ học sớm hoặc đi học muộn",
        Mam: ""
    },
    "9cC": {
        Arabic:
            "اضطر تلميذي كثيرًا إلى ترك المدرسة مبكرًا أو الذهاب إلى المدرسة متأخرًا",
        Bengali:
            "আমার ছাত্রকে প্রায়ই তাড়াতাড়ি স্কুল ছাড়তে হতো বা দেরিতে স্কুলে যেতে হতো।",
        English:
            "My student frequently had to leave school early or went to school late",
        French:
            "Mon élève a souvent dû quitter l'école tôt ou est allé à l'école tard",
        Portuguese:
            "Meu aluno freqüentemente tinha que sair da escola mais cedo ou ia para a escola tarde",
        Russian:
            "Моему ученику часто приходилось рано уходить из школы или поздно приходить в школу",
        Spanish:
            "Con frecuencia, mi estudiante tenía que salir temprano de la escuela o llegar tarde a la escuela",
        Vietnamese:
            "Học sinh của tôi thường xuyên phải nghỉ học sớm hoặc đi học muộn",
        Mam: ""
    },
    "104S": {
        Arabic: "",
        Bengali: "",
        English: "What do you like to read? Please check all that apply.",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "¿Qué te gusta leer? Por favor marca todos los que aplican.",
        Vietnamese: "",
        Mam: ""
    },
    "104C": {
        Arabic: "",
        Bengali: "",
        English: "What does your student like to read? Please check all that apply.",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish:
            "¿Qué le gusta leer a tu estudiante? Por favor marca todos los que aplican.",
        Vietnamese: "",
        Mam: ""
    },
    "104aS": {
        Arabic: "",
        Bengali: "",
        English: "Books",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Libros",
        Vietnamese: "",
        Mam: ""
    },
    "104bS": {
        Arabic: "",
        Bengali: "",
        English: "Comics",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Historietas / Comics",
        Vietnamese: "",
        Mam: ""
    },
    "104cS": {
        Arabic: "",
        Bengali: "",
        English: "Magazines",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Revistas",
        Vietnamese: "",
        Mam: ""
    },
    "104dS": {
        Arabic: "",
        Bengali: "",
        English: "Newspapers",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Periódicos",
        Vietnamese: "",
        Mam: ""
    },
    "104eS": {
        Arabic: "",
        Bengali: "",
        English: "Social media and other online reading",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Redes sociales y leyendo en el internet",
        Vietnamese: "",
        Mam: ""
    },
    "104aC": {
        Arabic: "",
        Bengali: "",
        English: "Books",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Libros",
        Vietnamese: "",
        Mam: ""
    },
    "104bC": {
        Arabic: "",
        Bengali: "",
        English: "Comics",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Historietas / Comics",
        Vietnamese: "",
        Mam: ""
    },
    "104cC": {
        Arabic: "",
        Bengali: "",
        English: "Magazines",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Revistas",
        Vietnamese: "",
        Mam: ""
    },
    "104dC": {
        Arabic: "",
        Bengali: "",
        English: "Newspapers",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Periódicos",
        Vietnamese: "",
        Mam: ""
    },
    "104eC": {
        Arabic: "",
        Bengali: "",
        English: "Reading online",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Redes sociales y leyendo en el internet",
        Vietnamese: "",
        Mam: ""
    },
    "None": {
        Arabic: "",
        Bengali: "",
        English: "None of these apply",
        French: "",
        Portuguese: "",
        Russian: "",
        Spanish: "Ninguno aplica",
        Vietnamese: "",
        Mam: ""
    },
    N: {
        Amharic: "አይ",
        Arabic: "لا",
        Bengali: "না",
        Burmese: "မဟုတ်ဘူး",
        ChineseSimplified: "没有",
        ChineseTraditional: "沒有",
        English: "No",
        French: "Non",
        HaitianCreole: "Non",
        Nepali: "होईन",
        Portuguese: "Não",
        Russian: "нет",
        Somali: "Maya",
        Spanish: "No",
        Swahili: "Hapana",
        Urdu: "نہیں",
        Vietnamese: "Không",
        Mam: ""
    },
    Y: {
        Amharic: "አዎ",
        Arabic: "نعم",
        Bengali: "হ্যাঁ",
        Burmese: "ဟုတ်တယ်",
        ChineseSimplified: "是",
        ChineseTraditional: "是",
        English: "Yes",
        French: "Oui",
        HaitianCreole: "Wi",
        Nepali: "हो",
        Portuguese: "Sim",
        Russian: "да",
        Somali: "Haa",
        Spanish: "Sí",
        Swahili: "Ndio",
        Urdu: "جی ہاں",
        Vietnamese: "Đúng",
        Mam: ""
    }
};
