//modules
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    Grid,
    Paper,
    TextField,
    Button,
    Typography,
    Link,
    Divider
} from "@material-ui/core";
import { toast } from "react-toastify";
//components
import { api } from "../helpers/axios";
import { useStyles } from "../styles/styles";
//images
import smallLogo from "../styles/images/small-logo.png";


export const SetPassword = () => {
    const { userId } = useParams();
    const { token } = useParams();
    const classes = useStyles();
    const navigate = useNavigate();

    const [password, setPassword] = useState("");
    const [passwordMatch, setPasswordMatch] = useState("");
    const [success, setSuccess] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [errorText2, setErrorText2] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);


    useEffect(() => {
        if (password.length >= 8 && passwordMatch.length >= 8) {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }
    },
        [password, passwordMatch, setButtonDisabled]
    );


    return (
        <Grid>
            <Paper elevation={3} className={classes.loginPaper}>
                <Grid align="center">
                    <img src={smallLogo} className={classes.loginLogo} alt="inlier logo" />
                    <Divider />
                    <Typography component="h3" className={classes.loginHeaderTypography}>
                        Set New Password
                    </Typography>
                </Grid>
                {!success && (<div>
                    <Typography>
                        Type new password
                    </Typography>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        label="new password"
                        name="password"
                        autoComplete="password"
                        autoFocus
                        onChange={e => {
                            setPassword(e.target.value);
                            if (password && password.length >= 8) {
                                setErrorText("")
                            }
                        }}
                        onBlur={e => {
                            if (password && password.length < 8) {
                                setErrorText('Password must be at least 8 characters long.')
                            } else if (password && password.length >= 8) {
                                setErrorText("")
                            }
                        }}
                        error={errorText.length === 0 ? false : true}
                        helperText={errorText}
                    />
                    <Typography style={{ paddingTop: "1.5em" }}>
                        Confirm your password
                    </Typography>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="passwordMatch"
                        label="new password"
                        name="passwordMatch"
                        autoComplete="passwordMatch"
                        autoFocus
                        onChange={e => {
                            setPasswordMatch(e.target.value);
                            if (passwordMatch && passwordMatch.length >= 8) {
                                setErrorText2("")
                            }
                        }}
                        onBlur={e => {
                            if (passwordMatch && passwordMatch.length < 8) {
                                setErrorText2('Password must be at least 8 characters long.')
                            } else if (passwordMatch && passwordMatch.length >= 8) {
                                setErrorText2("")
                            }
                        }}
                        error={errorText2.length === 0 ? false : true}
                        helperText={errorText2}
                    />
                    <Button
                        disabled={buttonDisabled}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={async e => {
                            e.preventDefault();
                            //axios call to login route
                            if (password !== passwordMatch) {
                                toast.error(
                                    `Passwords don't match.`
                                );
                            } else {
                                api
                                    .post(
                                        `/auth/setpassword/${userId}/${token}`,
                                        { password },
                                        {
                                            withCredentials: true,
                                            headers: {
                                                // "Content-Type": "application/json"
                                            }
                                        }
                                    )
                                    .then(async response => {
                                        if (response && response.data && response.data.status === 200) {
                                            setSuccess(true);
                                        }
                                    })
                                    .catch(err => {
                                        if (err.response.data.message === "Error: Invalid link or expired") {
                                            toast.error(
                                                err.response.data.message
                                            );
                                        } else {
                                            toast.error(
                                                `Cannot set new password. Please try again later.`
                                            );
                                        }

                                    });
                            }
                        }}
                    >
                        Set password
                    </Button>
                </div>)}
                {success && (
                    <div>
                        Success! You can now sign in with your new password.
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={async e => {
                                e.preventDefault();
                                navigate("/")
                            }}
                        >
                            Login
                        </Button>
                    </div>
                )}
                <div className={classes.loginHelpLinks}>
                    Click "Request Help" to contact us.
                    <div>
                        <Link href="https://www.inlierlearning.com/requestapphelp" target="_blank" rel="noreferrer" >
                            Request Help
                        </Link>
                    </div>
                </div>
            </Paper>
        </Grid>
    );
};
