import { useField } from "formik";
import { Checkbox, FormControlLabel } from "@material-ui/core";

export const MyCheckbox = ({ label, language, ...props }) => {
  let labelPlacement = "";

  if (language === "Arabic") {
    labelPlacement = "start";
  }
  const [field] = useField(props);
  return (
    <FormControlLabel
      {...field}
      control={<Checkbox />}
      label={<span style={{ fontSize: 19 }}>{label}</span>}
      labelPlacement={labelPlacement}
    />
  );
};
