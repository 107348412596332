//TODO: fix - info also in contentByGradeAndLanguageObj.ts
export const literacyPassagesV1 = {
  "5": {
    passage: {
      id: "lit5-78363",
      title: `La Mariposa Monarca y El Día de Muertos`,
      body: `Es costumbre en México celebrar el Día de Muertos en noviembre, una práctica mesoamericana que celebra tanto la vida como la muerte en la que las familias conmemoran a sus seres queridos fallecidos. Como un reloj, la mariposa monarca vuela de regreso a México para esta celebración en noviembre. ¿Será casualidad que esta hermosa y colorida mariposa vuela de regreso mientras sucede esta celebración en honor a nuestros seres difuntos? O la mariposa monarca aparece cuando llega el momento de cosechar el maíz.`,
      audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit5-78363-spanish.mp3`,
      questions: {
        "lit5-78363-1": {
          id: "lit5-78363-1",
          body: `¿Qué celebración toma lugar en México en noviembre?`,
          correctId: "lit5-78363-1c",
          audio: null,
          options:
            [{
              id: "lit5-78363-1a",
              audio: null,
              body: `Día de la Independencia`,
            },
            {
              id: "lit5-78363-1b",
              audio: null,
              body: `Día de la Revolución`,
            },
            {
              id: "lit5-78363-1c",
              audio: null,
              body: `Día de Muertos`,
            },
            {
              id: "lit5-78363-1d",
              audio: null,
              body: `Dia de la Batalla de Puebla`,
            }]
        },
        "lit5-78363-2": {
          id: "lit5-78363-2",
          body: `¿Qué se celebra el día de los Muertos?`,
          correctId: "lit5-78363-2c",
          audio: null,
          options:
            [{
              id: "lit5-78363-2a",
              audio: null,
              body: `Seres queridos que fallecieron`,
            },
            {
              id: "lit5-78363-2b",
              audio: null,
              body: `Reunion familiar`,
            },
            {
              id: "lit5-78363-2c",
              audio: null,
              body: `La celebración de la vida y la muerte de seres difuntos`,
            },
            {
              id: "lit5-78363-2d",
              audio: null,
              body: `La celebración de la flor celpacuchil`,
            }]
        },
        "lit5-78363-3": {
          id: "lit5-78363-3",
          body: `¿Por qué crees que la mariposa monarca regresa a México en noviembre?`,
          correctId: "lit5-78363-3d",
          audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit5-78363-3-spanish.mp3`,
          options:
            [{
              id: "lit5-78363-3a",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit5-78363-3a-spanish.mp3`,
              body: `En honor a los seres difuntos`,
            },
            {
              id: "lit5-78363-3b",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit5-78363-3b-spanish.mp3`,
              body: `La cosecha del maiz`,
            },
            {
              id: "lit5-78363-3c",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit5-78363-3c-spanish.mp3`,
              body: `Respuestas a y b`,
            },
            {
              id: "lit5-78363-3d",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit5-78363-3d-spanish.mp3`,
              body: `El regreso es solo una coincidencia`,
            }]
        },
        "lit5-78363-4": {
          id: "lit5-78363-4",
          body: `¿Qué significa que la mariposa monarca regresa a México como un reloj?`,
          correctId: "lit5-78363-4b",
          audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit5-78363-4-spanish.mp3`,
          options:
            [{
              id: "lit5-78363-4a",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit5-78363-4a-spanish.mp3`,
              body: `La mariposa monarca tiene un reloj interno.`,
            },
            {
              id: "lit5-78363-4b",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit5-78363-4b-spanish.mp3`,
              body: `La mariposa regresa cada noviembre sin fallar.`,
            },
            {
              id: "lit5-78363-4c",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit5-78363-4c-spanish.mp3`,
              body: `El reloj le ayuda a la mariposa monarca a llegar a su destino`,
            },
            {
              id: "lit5-78363-4d",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit5-78363-4d-spanish.mp3`,
              body: `La mariposa monarca sólo regresa a México.`,
            }]
        }
      }
    }
  },
  "6": {
    passage: {
      id: "lit6-77236",
      title: `El hábitat de la mariposa monarca`,
      body: `La mariposa monarca está en peligro de perder su hábitat natural. La pérdida de su hábitat se debe al impacto que ha tenido el clima, la deforestación y las prácticas agrícolas en las plantas de algodoncillo; el hábitat natural de las mariposas monarcas. Los cambios climatológicos como la temperaturas extremas, sequías y árboles caídos por desastres naturales han disminuido o matado las plantas de algodoncillo en las áreas en donde la mariposa monarca suele reproducirse. Otra causa ha sido la deforestación ilegal en la Reserva de la Biósfera Santuario Mariposa Monarca. También han afectado el hábitat de las mariposas las prácticas agrícolas del uso de herbicidas y la falta de espacio para plantar plantas de algodoncillo ocupado por los sembradíos de maíz y soja.`,
      audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit6-77236-spanish.mp3`,
      questions: {
        "lit6-77236-1": {
          id: "lit6-77236-1",
          body: `¿Qué es un hábitat?`,
          correctId: "lit6-77236-1b",
          audio: null,
          options:
            [{
              id: "lit6-77236-1a",
              audio: null,
              body: `Los hábitos diarios de los insectos`,
            },
            {
              id: "lit6-77236-1b",
              audio: null,
              body: `El lugar geográfico o factores físicos que ayudan al desarrollo de una especie o grupo de especies.`,
            },
            {
              id: "lit6-77236-1c",
              audio: null,
              body: `Los elementos naturales de un lugar`,
            },
            {
              id: "lit6-77236-1d",
              audio: null,
              body: `Los sembradíos de maíz y soya`,
            }]
        },
        "lit6-77236-2": {
          id: "lit6-77236-2",
          body: `¿Qué palabra puede reemplazar el impacto sin que cambie el significado en la oración?`,
          correctId: "lit6-77236-2c",
          audio: null,
          options:
            [{
              id: "lit6-77236-2a",
              audio: null,
              body: `turbación`,
            },
            {
              id: "lit6-77236-2b",
              audio: null,
              body: `huella`,
            },
            {
              id: "lit6-77236-2c",
              audio: null,
              body: `efecto`,
            },
            {
              id: "lit6-77236-2d",
              audio: null,
              body: `golpe`,
            }]
        },
        "lit6-77236-3": {
          id: "lit6-77236-3",
          body: `¿Qué ejemplos puedes encontrar para explicar las prácticas agrícolas que han afectado el hábitat de la mariposa monarca?`,
          correctId: "lit6-77236-3b",
          audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit6-77236-3-spanish.mp3`,
          options:
            [{
              id: "lit6-77236-3a",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit6-77236-3a-spanish.mp3`,
              body: `El arado para la preparación de sembradíos`,
            },
            {
              id: "lit6-77236-3b",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit6-77236-3b-spanish.mp3`,
              body: `Los herbicidas y falta de terreno para plantar las plantas de algodoncillo`,
            },
            {
              id: "lit6-77236-3c",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit6-77236-3c-spanish.mp3`,
              body: `Deshierbar o quitar la maleza de los sembrados`,
            },
            {
              id: "lit6-77236-3d",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit6-77236-3d-spanish.mp3`,
              body: `Los herbicidas y la irrigación por goteo`,
            }]
        },
        "lit6-77236-4": {
          id: "lit6-77236-4",
          body: `¿Cuáles son los motivos que contribuyen a la pérdida de hábitat de la mariposa monarca?`,
          correctId: "lit6-77236-4d",
          audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit6-77236-4-spanish.mp3`,
          options:
            [{
              id: "lit6-77236-4a",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit6-77236-4a-spanish.mp3`,
              body: `La falta o escasez de plantas y árboles`,
            },
            {
              id: "lit6-77236-4b",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit6-77236-4b-spanish.mp3`,
              body: `Los múltiples desastres naturales`,
            },
            {
              id: "lit6-77236-4c",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit6-77236-4c-spanish.mp3`,
              body: `Las prácticas cotidianas de los seres humanos`,
            },
            {
              id: "lit6-77236-4d",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit6-77236-4d-spanish.mp3`,
              body: `La deforestación, los cambios climatológicos y las prácticas agrícolas`,
            }]
        }
      }
    }

  },
  "7": {
    passage: {
      id: "lit7-12998",
      title: `Los Cinco Sentidos de las Mariposa Monarca`,
      body: ` Cuando uno piensa en los cinco sentidos que son el olfato, el gusto, el tacto, el oído, y la vista no pensamos en la mariposa monarca y su don navegando estos sentidos. Primeramente, el olfato y el gusto de la mariposa monarca obtiene gran parte de su información sobre el mundo a través de quimiorreceptores que pueden monitorear estímulos externos. El tacto de esta bella mariposa se puede percibir a través de pequeños pelos llamados setas táctiles para usar durante el vuelo porque ayudan a la mariposa a sentir la gravedad, el viento y la posición de sus alas. En general, esta mariposa tiene problemas de audición pero en el estado de larva perciben el sonido a través de setas táctiles. Finalmente, la mariposa monarca se ve de manera muy diferente durante las diferentes etapas de su vida. Por ejemplo, la visión de las larvas es limitada y deficiente a lo contrario la visión de los adultos ven a través de ojos compuestos formados por miles de omatidios que recogen la luz y procesa la información visual.`,
      audio: "https://storage.googleapis.com/inlier-audio/literacy/spanish/lit7-12998-spanish.mp3",
      questions: {
        "lit7-12998-1": {
          id: "lit7-12998-1",
          body: `¿Qué significa quimiorreceptores?`,
          correctId: "lit7-12998-1b",
          audio: null,
          options:
            [{
              id: "lit7-12998-1a",
              audio: null,
              body: `Los ojos compuestos formados por miles de omatidios`,
            },
            {
              id: "lit7-12998-1b",
              audio: null,
              body: `Estos receptores pueden monitorear estímulos externos`,
            },
            {
              id: "lit7-12998-1c",
              audio: null,
              body: `Los setas táctiles para usar durante el vuelo`,
            },
            {
              id: "lit7-12998-1d",
              audio: null,
              body: `Los omatidios que recogen la luz y procesa la información visual`,
            }]
        },
        "lit7-12998-2": {
          id: "lit7-12998-2",
          body: `¿Por qué piensas que la mariposa monarca se ve de manera muy diferente durante las diferentes etapas de su vida?`,
          correctId: "lit7-12998-2a",
          audio: null,
          options:
            [{
              id: "lit7-12998-2a",
              audio: null,
              body: `Porque depende de la etapa de su vida cuando forman la información visual.`,
            },
            {
              id: "lit7-12998-2b",
              audio: null,
              body: `Porque no tienen ojos compuestos`,
            },
            {
              id: "lit7-12998-2c",
              audio: null,
              body: `Porque las larvas tienen visión limitada`,
            },
            {
              id: "lit7-12998-2d",
              audio: null,
              body: `Porque necesitan más estímulo`,
            }]
        },
        "lit7-12998-3": {
          id: "lit7-12998-3",
          body: `¿Cuál es el tema principal de este párrafo?`,
          correctId: "lit7-12998-3d",
          audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit7-12998-3-spanish.mp3`,
          options:
            [{
              id: "lit7-12998-3a",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit7-12998-3a-spanish.mp3`,
              body: `El olfato y el gusto de las mariposas monarcas`,
            },
            {
              id: "lit7-12998-3b",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit7-12998-3b-spanish.mp3`,
              body: `El oído y la vista de las mariposas monarcas`,
            },
            {
              id: "lit7-12998-3c",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit7-12998-3c-spanish.mp3`,
              body: `Los quimiorreceptores que pueden monitorear estímulos externos`,
            },
            {
              id: "lit7-12998-3d",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit7-12998-3d-spanish.mp3`,
              body: `El don de utilizar los cinco sentidos para navegar su vuelo`,
            }]
        },
        "lit7-12998-4": {
          id: "lit7-12998-4",
          body: `¿Qué evidencia puedes encontrar en el párrafo sobre la mariposa monarca y su don navegando en estos sentidos?`,
          correctId: "lit7-12998-4a",
          audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit7-12998-4-spanish.mp3`,
          options:
            [{
              id: "lit7-12998-4a",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit7-12998-4a-spanish.mp3`,
              body: `El tacto de esta mariposa monarca se puede percibir a través de setas táctiles.`,
            },
            {
              id: "lit7-12998-4b",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit7-12998-4b-spanish.mp3`,
              body: `El vuelo de la mariposa monarca es lento.`,
            },
            {
              id: "lit7-12998-4c",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit7-12998-4c-spanish.mp3`,
              body: `La mariposa monarca tiene una jornada muy larga en el invierno.`,
            },
            {
              id: "lit7-12998-4d",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit7-12998-4d-spanish.mp3`,
              body: `La mariposa monarca tiene una jornada muy larga y a veces peligrosa.`,
            }]
        }

      }
    }
  },
  "8": {
    passage: {
      id: "lit8-10048",
      title: `El Destino de la Mariposa Monarca en el Este de los Estados Unidos`,
      body: `La mariposa monarca tiene dos rutas migratorias en los Estados Unidos; unas viajan al oeste y las otras al este del país. Los estados de la ruta este de la mariposa monarca incluye Florida, Virginia, Texas, Kansas y Iowa. Florida es la última parada de la mariposa monarca en los Estados Unidos antes de emprender su viaje hacia el Golfo de México. A principios de septiembre y a finales del mes de octubre la mariposa monarca cada año toma residencia en Virginia y en el área central de Texas en las praderas y sabanas de roble. En Kansas, a mitad de septiembre, la mariposa monarca busca alimento en los ásteres, varas de oro y otras flores silvestres orgánicas del refugio en los pantanos del centro del estado. En Iowa las mariposas monarcas se posan a lo largo del Sendero de Hierba Alta y de los caminos del refugio. Sin embargo las mariposas monarcas también son vistas y se reproducen en áreas urbanas como en Austin, Chicago, las ciudades gemelas y la ciudad de Kansas.`,
      audio: "https://storage.googleapis.com/inlier-audio/literacy/spanish/lit8-10048-spanish.mp3",
      questions: {
        "lit8-10048-1": {
          id: "lit8-10048-1",
          body: `¿Cuáles son las rutas migratorias de la mariposa monarca en los Estados Unidos?`,
          correctId: "lit8-10048-1b",
          audio: null,
          options:
            [{
              id: "lit8-10048-1a",
              audio: null,
              body: `Las mariposas monarcas viajan a México, Estados Unidos y Canadá.`,
            },
            {
              id: "lit8-10048-1b",
              audio: null,
              body: `En los Estados Unidos la mariposa monarca viaja al oeste y otras al este del pais.`,
            },
            {
              id: "lit8-10048-1c",
              audio: null,
              body: `Las mariposas monarcas viajan al norte del país.`,
            },
            {
              id: "lit8-10048-1d",
              audio: null,
              body: `Las mariposas viajan a Austin y Chicago solamente.`,
            }]
        },
        "lit8-10048-2": {
          id: "lit8-10048-2",
          body: `¿Qué palabra puede reemplazar áreas urbanas sin que cambie el significado en la oración?`,
          correctId: "lit8-10048-2b",
          audio: null,
          options:
            [{
              id: "lit8-10048-2a",
              audio: null,
              body: `comercio`,
            },
            {
              id: "lit8-10048-2b",
              audio: null,
              body: `zonas metropolitanas`,
            },
            {
              id: "lit8-10048-2c",
              audio: null,
              body: `turísticas`,
            },
            {
              id: "lit8-10048-2d",
              audio: null,
              body: `campo`,
            }]
        },
        "lit8-10048-3": {
          id: "lit8-10048-3",
          body: `¿Cuáles ejemplos puedes encontrar para explicar la ruta de la mariposa monarca en los Estados Unidos?`,
          correctId: "lit8-10048-3c",
          audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit8-10048-3-spanish.mp3`,
          options:
            [{
              id: "lit8-10048-3a",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit8-10048-3a-spanish.mp3`,
              body: `La mariposa monarca viaja a Florida y Virginia.`,
            },
            {
              id: "lit8-10048-3b",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit8-10048-3b-spanish.mp3`,
              body: `La ruta de la mariposa monarca incluye el Golfo de México.`,
            },
            {
              id: "lit8-10048-3c",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit8-10048-3c-spanish.mp3`,
              body: `La mariposa monarca viaja al este, oeste del país incluyendo zonas urbanas.`,
            },
            {
              id: "lit8-10048-3d",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit8-10048-3d-spanish.mp3`,
              body: `La mariposa monarca también viaja al estado de Georgia.`,
            }]
        },
        "lit8-10048-4": {
          id: "lit8-10048-4",
          body: `¿A cuáles conclusiones puedes llegar acerca de las áreas que son parte de la ruta migratoria de la mariposa monarca? `,
          correctId: "lit8-10048-4a",
          audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit8-10048-4-spanish.mp3`,
          options:
            [{
              id: "lit8-10048-4a",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit8-10048-4a-spanish.mp3`,
              body: `La mariposa monarca prefiere estar en climas cálidos.`,
            },
            {
              id: "lit8-10048-4b",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit8-10048-4b-spanish.mp3`,
              body: `Las mariposas monarcas prefieren las zonas rurales y las urbanas.`,
            },
            {
              id: "lit8-10048-4c",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit8-10048-4c-spanish.mp3`,
              body: `Las mariposas monarcas prefieren los pantanos.`,
            },
            {
              id: "lit8-10048-4d",
              audio: `https://storage.googleapis.com/inlier-audio/literacy/spanish/lit8-10048-4d-spanish.mp3`,
              body: `Las mariposas monarcas prefiere los sábanas.`,
            }]
        }
      }
    },
  }
}

