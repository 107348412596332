import React, { useEffect, useState } from "react";
import ErrorBlock from "../ErrorBlock";
import { ScreenerCheckbox } from "../../AnswerFields/ScreenerCheckbox";
import { useStyles } from "../../../styles/styles";
import VolumeUpOutlinedIcon from "@material-ui/icons/VolumeUpOutlined";
import Grid from '@mui/material/Grid';

export const AnswerOptions59209 = ({
    language,
    errors,
    touched,
    options
}) => {
    const classes = useStyles();
    const { glowing } = useStyles();

    //color 
    const [color, setColor] = useState();
    const [colorTwo, setColorTwo] = useState();
    const [colorThree, setColorThree] = useState();
    const [colorFour, setColorFour] = useState();
    const [colorFive, setColorFive] = useState();
    const [colorSix, setColorSix] = useState();
    const [colorSeven, setColorSeven] = useState();
    const [colorEight, setColorEight] = useState();
    const [colorNine, setColorNine] = useState();
    const [colorTen, setColorTen] = useState();
    const [colorEleven, setColorEleven] = useState();
    const [colorTwelve, setColorTwelve] = useState();
    const [colorThirteen, setColorThirteen] = useState()

    const handleAudioHighlight = (start, end, stateColorVar) => {

        const highlightColor = "#66ff66"

        setTimeout(
            () => {
                stateColorVar(highlightColor)
            }, start);

        setTimeout(
            () => {
                stateColorVar("")
            }, end);
    }

    return (
        <div style={{ paddingTop: "13px", paddingBottom: "13px" }}>
            <div className={classes.buttons}>
                <VolumeUpOutlinedIcon
                    className={`${glowing}`}
                    fontSize="small"
                    onClick={(e) => {
                        e.preventDefault();
                        let sound = new Audio(options[0].audio);
                        sound.play();

                        handleAudioHighlight(400, 1200, setColor)
                        handleAudioHighlight(1300, 2100, setColorTwo)
                        handleAudioHighlight(2050, 3000, setColorThree)
                        handleAudioHighlight(3100, 4000, setColorFour)
                        handleAudioHighlight(4100, 6000, setColorFive)
                        handleAudioHighlight(6100, 7000, setColorSix)
                        handleAudioHighlight(7100, 8000, setColorSeven)
                        handleAudioHighlight(8100, 10500, setColorEight)
                        handleAudioHighlight(10600, 11600, setColorNine)
                        handleAudioHighlight(11700, 12700, setColorTen)
                        handleAudioHighlight(12800, 14500, setColorEleven)
                        handleAudioHighlight(14600, 15600, setColorTwelve)
                        handleAudioHighlight(15700, 16700, setColorThirteen)
                    }}
                    style={{ cursor: "pointer" }}
                ></VolumeUpOutlinedIcon>
            </div>
            <Grid container>
                <Grid container item xs={8} sm={6} direction="column" alignItems={"flex-start"} paddingLeft={"5em"}>
                    <Grid item><ScreenerCheckbox language={language} text={options[0].text} englishText={options[0].englishText} altName={options[0].altName} highlight={color} /></Grid>
                    <Grid item><ScreenerCheckbox language={language} text={options[1].text} englishText={options[1].englishText} altName={options[1].altName} highlight={colorTwo} /></Grid>
                    <Grid item><ScreenerCheckbox language={language} text={options[2].text} englishText={options[2].englishText} altName={options[2].altName} highlight={colorThree} /></Grid>
                    <Grid item><ScreenerCheckbox language={language} text={options[3].text} englishText={options[3].englishText} altName={options[3].altName} highlight={colorFour} /></Grid>
                    <Grid item><ScreenerCheckbox language={language} text={options[4].text} englishText={options[4].englishText} altName={options[4].altName} highlight={colorFive} /></Grid>
                    <Grid item><ScreenerCheckbox language={language} text={options[5].text} englishText={options[5].englishText} altName={options[5].altName} highlight={colorSix} /></Grid>
                    <Grid item><ScreenerCheckbox language={language} text={options[6].text} englishText={options[6].englishText} altName={options[6].altName} highlight={colorSeven} /></Grid>
                </Grid>
                <Grid container item xs={8} sm={6} direction="column" alignItems={"flex-start"} paddingLeft={"5em"}>
                    <Grid item><ScreenerCheckbox language={language} text={options[7].text} englishText={options[7].englishText} altName={options[7].altName} highlight={colorEight} /></Grid>
                    <Grid item><ScreenerCheckbox language={language} text={options[8].text} englishText={options[8].englishText} altName={options[8].altName} highlight={colorNine} /></Grid>
                    <Grid item><ScreenerCheckbox language={language} text={options[9].text} englishText={options[9].englishText} altName={options[9].altName} highlight={colorTen} /></Grid>
                    <Grid item><ScreenerCheckbox language={language} text={options[10].text} englishText={options[10].englishText} altName={options[10].altName} highlight={colorEleven} /></Grid>
                    <Grid item><ScreenerCheckbox language={language} text={options[11].text} englishText={options[11].englishText} altName={options[11].altName} highlight={colorTwelve} /></Grid>
                    <Grid item><ScreenerCheckbox language={language} text={options[12].text} englishText={options[12].englishText} altName={options[12].altName} highlight={colorThirteen} /></Grid>
                </Grid>
            </Grid>
            {errors !== undefined && touched !== undefined && (
                <ErrorBlock language={language} errors={errors} touched={touched} />
            )}
        </div>
    );
};