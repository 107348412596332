//modules
import React, { useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
//components
import onSubmitForm from "../../helpers/Screener/onSubmitForm";
import EnglishPopup from "./EnglishPopup";
import { ButtonBlock } from "./buttonBlock";
import { QuestionText } from "./QuestionText";
import { AnswerOptions } from "./AnswerOptions";
import { ImageGrid } from "./Question1/ImageGrid";
import { cityImageList } from "./Question1/cityImageList";
import { generateBackgroundQuestionHelper } from "../../helpers/Screener/generateBackgroundQuestionHelper";
import { lastGradeCompleted } from "../../objects/Screener/lastGradeCompletedObj";
import NumSelect from "./NumSelect";
import { ageRangeNums } from "../../objects/Screener/ageRangeNums";
import { AnswerOptions59209 } from "./Question59209/AnswerOptions59209";
import Divider from '@mui/material/Divider';

export const QuestionForm = ({ questionId, language, userType, formValues, setFormValues }) => {
  const navigate = useNavigate();
  const tempData = { formData: "" };
  const getData = () => formValues || tempData;

  let first = false;
  if (questionId === "59209") {
    first = true
  }

  let numSelect = ageRangeNums;
  if (questionId === "7") {
    numSelect = lastGradeCompleted;
  }

  //english popup
  const [popup, setPopup] = useState(false);


  let bgQuestion = generateBackgroundQuestionHelper(userType, language, questionId);
  // console.log("STUFF", bgQuestion)

  //deals with the overflow background color
  document.body.style.backgroundColor = "#E5E8E9";

  return (
    <Formik
      enableReinitialize={true}
      initialValues={getData()}
      onSubmit={async (data, { setSubmitting }) => {
        setSubmitting(true);
        await onSubmitForm(
          data,
          navigate,
          questionId,
          language,
          popup
        );
        setSubmitting(false);
      }}
    >
      <div style={{ borderStyle: "solid", borderColor: "rgba(0, 0, 0, 0)" }}>
        <EnglishPopup
          question={bgQuestion.englishQuestionText}
          options={bgQuestion.options}
          stateChanger={setPopup}
        />
        <Form>
          <div style={{ marginTop: "2.5em" }}>
            <QuestionText
              text={bgQuestion.questionText}
              questionAudio={bgQuestion.questionAudio}
            />
          </div>
          <Divider style={{ padding: "0.5em" }} />
          {(questionId === "59209" && questionId !== "5" && questionId !== "7") && (
            <AnswerOptions59209
              language={language}
              options={bgQuestion.options}
            />
          )}
          {(questionId !== "4" && questionId !== "5" && questionId !== "7" && questionId !== "59209") && (
            <AnswerOptions
              language={language}
              options={bgQuestion.options}
            />
          )}
          {(questionId === "4" || questionId === "5" || questionId === "7") && (
            <NumSelect
              language={language}
              numbers={numSelect}
              formValues={formValues}
              setFormValues={setFormValues}
            />
          )}
          {questionId === "1" && (
            <ImageGrid itemData={cityImageList} />
          )}
          <ButtonBlock language={language} first={first} />
        </Form>
      </div>
    </Formik>
  );
};
