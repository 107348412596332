export const audioStrHelper = (userType, language, audioId) => {
    language = language.toLowerCase();
    audioId = audioId.replace("S", "");
    audioId = audioId.replace("C", "");

    if (
        userType === 3 &&
        audioId !== "2a" &&
        audioId !== "2b" &&
        audioId !== "102a" &&
        audioId !== "102b" &&
        audioId !== "N" &&
        audioId !== "Y" &&
        audioId !== "None" &&
        audioId !== "3a" &&
        audioId !== "3i" &&
        audioId !== "104a" &&
        audioId !== "104b" &&
        audioId !== "104c" &&
        audioId !== "104d" &&
        audioId !== "104e"
    ) {
        audioId = audioId + "-c";
    }

    return `https://storage.googleapis.com/inlier-audio/aug-2021/${language}/${audioId}-${language}.mp3`;
};
