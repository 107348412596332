import React from "react";
import ErrorBlock from "./ErrorBlock";

import { ScreenerRadio } from "../AnswerFields/ScreenerRadio";
import { ScreenerCheckbox } from "../AnswerFields/ScreenerCheckbox";
import Divider from "@mui/material/Divider";

export const AnswerOptions = ({
    language,
    errors,
    touched,
    options
}) => {

    return (
        <div style={{ paddingTop: "13px", paddingBottom: "13px" }}>
            {options.map((option, i) => (
                <div>
                    {option.type === "radio" && (
                        <ScreenerRadio language={language} audio={option.audio} text={option.text} englishText={option.englishText} altName={option.altName} />
                    )}
                    {/* dividers for question 8 */}
                    {option.altName && (i === 2 || i === 4) && (
                        <Divider />
                    )}
                    {option.type === "checkbox" && (
                        <ScreenerCheckbox language={language} audio={option.audio} text={option.text} englishText={option.englishText} altName={option.altName} />
                    )}
                </div>
            ))}
            {errors !== undefined && touched !== undefined && (
                <ErrorBlock language={language} errors={errors} touched={touched} />
            )}
        </div>
    );
};
