import Link from "@material-ui/core/Link";

export const Copyright = () => {
  return (
    <div variant="body2" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://inlierlearning.com">
        Inlier Learning, Inc.
      </Link>{" "}
      {new Date().getFullYear()}
    </div>
  );
};
