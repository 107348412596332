import * as React from "react";
import { styled } from "@mui/material/styles";

const ImageGalleryList = styled("ul")(({ theme }) => ({
  display: "grid",
  padding: 0,
  gap: 8,
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "repeat(2, 1fr)"
  },
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "repeat(4, 1fr)"
  },
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "repeat(4, 1fr)"
  }
}));

export const ImageGrid = ({ itemData }) => {
  return (
    <div>
      <div style={{ textAlign: "left" }}>Ciudad:</div>
      <ImageGalleryList>
        {itemData.map((item) => (
          <div key={item.img}>
            <img
              style={{ height: "11.5em", width: "17.5em", borderRadius: "20px", padding: "0.5em", filter: "drop-shadow(2px 2px 2px gray)" }}
              src={item.img}
              srcSet={item.img}
              alt={item.title}
            />
          </div>
        ))}
      </ImageGalleryList>
    </div>
  );
};
