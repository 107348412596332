//modules
import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useNavigate } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { BallTriangle } from "react-loader-spinner";
import { useParams } from "react-router-dom";
//components
import { QuestionForm } from "../components/Screener/QuestionForm";
import { useStyles } from "../styles/styles";
import { ScreenerFooter } from "../components/ScreenerFooter";
import { AppBarAndDrawer } from "../components/AppNavBar/AppBarAndDrawer";
import { idToLanguage } from "../objects/nativeLanguageId";
import { api } from "../helpers/axios";
import { toast } from "react-toastify";
import { useTabletAndBelowMediaQuery } from "../helpers/usingMediaQuery";

export const Background = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [userType, setUserType] = useState("");
  const [language, setLanguage] = useState("");
  const [formValues, setFormValues] = useState({ formData: "" });
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [tabletAndBelow, setTabletAndBelow] = useState(null);
  const accessToken = window.localStorage.getItem("iat");
  const isTabletAndBelow = useTabletAndBelowMediaQuery();

  useEffect(() => {
    if (isTabletAndBelow) {
      setTabletAndBelow(true)
    } else {
      setTabletAndBelow(false)
    }

  }, [setTabletAndBelow, isTabletAndBelow]);

  useEffect(
    () => {
      setFormValues({ formData: "" });
      const getData = async () => {
        api
          .get(`/student-app/answer/${id}`, {
            headers: {
              "Content-type": "application/json",
              authorization: `Bearer ${accessToken}`
            }
          })
          .then(res => {
            if (res.data.message[0] && res.data.message[0].userType) {
              setUserType(res.data.message[0].userType);
            }
            if (res.data.message[0] && res.data.message[0].language) {
              setLanguage(idToLanguage[res.data.message[0].language]);
            }
            if (
              res.data.message[1] &&
              res.data.message[1].answerText &&
              res.data.message[1].questionId === "8"
            ) {
              let question8Ans = JSON.parse(res.data.message[1].answerText);
              let values = {};
              if (question8Ans.classGender) {
                values.classGender = question8Ans.classGender;
              }
              if (question8Ans.classSize) {
                values.classSize = question8Ans.classSize;
              }
              if (question8Ans.other) {
                values.other = question8Ans.other;
              }
              setFormValues({
                formData: values
              });
            } else if (
              res.data.message[1] &&
              res.data.message[1].answerText &&
              res.data.message[1].answerText.includes("Yes, ")
            ) {
              setFormValues({
                formData: res.data.message[1].answerText
              });
            } else if (
              res.data.message[1] &&
              res.data.message[1].answerText &&
              res.data.message[1].answerText.includes(", ")
            ) {
              setFormValues({
                formData: res.data.message[1].answerText.split(", ")
              });
              //handles questions with checkboxes as options, when only one option was chosen
            } else if (res.data.message[1] && res.data.message[1].answerText && (
              res.data.message[1].questionId === "59209"
              || res.data.message[1].questionId === "104"
              || res.data.message[1].questionId === "3"
              || res.data.message[1].questionId === "9")) {
              setFormValues({
                formData: [res.data.message[1].answerText]
              });
            }
            else if (res.data.message[1] && res.data.message[1].answerText) {
              setFormValues({
                formData: res.data.message[1].answerText
              });
            }
          })
          .then(() => {
            return api.get(`/student-app/status`, {
              headers: {
                "Content-type": "application/json",
                authorization: `Bearer ${accessToken}`
              }
            })
          })
          .then(res => {
            if (res.status === 200 && res.data && res.data.message && res.data.message.answers && res.data.message.answers.length > 0) {
              setStatus(res.data.message.answers);
            }
          })
          .then(() => {
            setLoading(false);
          })
          .catch(err => {
            toast.error(`Server error. Please try again later. ${err.message}`);
            //ending the session
            window.localStorage.clear();
            return navigate("/");
          });
      };

      getData();
    },
    [accessToken, id, navigate]
  );

  if (!loading && accessToken) {
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBarAndDrawer status={status} />
        {!tabletAndBelow && (
          <main className={classes.content}>
            <Container className={classes.screenerContainer}>
              <Paper
                className={classes.screenerPaper}
                style={{ backgroundColor: "white" }}
                elevation={2}
              >
                <QuestionForm
                  questionId={id}
                  language={language}
                  userType={userType}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              </Paper>
            </Container>
            <ScreenerFooter />
          </main>
        )}
        {tabletAndBelow && (
          <main className={classes.content}>
            <Container className={classes.screenerContainer}>
              <Paper
                className={classes.mobileScreenerPaper}
                style={{ backgroundColor: "white" }}
                elevation={2}
              >
                <QuestionForm
                  questionId={id}
                  language={language}
                  userType={userType}
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              </Paper>
            </Container>
            <ScreenerFooter />
          </main>
        )}
      </div>
    );
  } else {
    return (
      <div className={classes.loader}>
        <BallTriangle color="#1E272E" type="BallTriangle" />
      </div>
    );
  }
};
