//modules
import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { useParams, useNavigate } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";
import { toast } from "react-toastify";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
//components
import { api } from "../helpers/axios";
import { useStyles } from "../styles/styles";
import { AppBarAndDrawer } from "../components/AppNavBar/AppBarAndDrawer";
import { LiteracyItem } from "../components/Literacy/LiteracyItem";


export const Literacy = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const classes = useStyles();
    const accessToken = window.localStorage.getItem("iat");

    const [status, setStatus] = useState(null);
    const [formValues, setFormValues] = useState({ formData: "" });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setFormValues({ formData: "" });
        const getData = async () => {
            api
                .get(`/student-app/answer/${id}`, {
                    headers: {
                        "Content-type": "application/json",
                        authorization: `Bearer ${accessToken}`
                    }
                })
                .then(res => {
                    if (res.data.message[1] && res.data.message[1].answerText) {
                        setFormValues({
                            formData: res.data.message[1].answerText
                        });
                    }
                })
                .then(() => {
                    return api.get(`/student-app/status`, {
                        headers: {
                            "Content-type": "application/json",
                            authorization: `Bearer ${accessToken}`
                        }
                    })
                })
                .then(res => {
                    if (res.status === 200 && res.data && res.data.message && res.data.message.answers && res.data.message.answers.length > 0) {
                        setStatus(res.data.message.answers);
                    }
                })
                .then(() => {
                    setLoading(false);
                })
                .catch((error) => {
                    toast.error(`Server error. Please try again later. ${error.message}`);
                    //ending the session
                    window.localStorage.clear();
                    return navigate("/");
                });
        };
        getData();
    }, [accessToken, setStatus, id, navigate]);

    if (!loading && accessToken) {
        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBarAndDrawer status={status} />
                <main className={classes.content}>
                    <Container maxWidth="l" className={classes.screenerContainer}>
                        <Paper
                            className={classes.literacyPaper}
                            style={{ backgroundColor: "white" }}
                            elevation={2}
                        >
                            <LiteracyItem
                                questionId={id}
                                formValues={formValues}
                            />
                        </Paper>
                    </Container>
                </main>
            </div>
        );
    } else {
        return (
            <div className={classes.loader}>
                <BallTriangle color="#1E272E" type="BallTriangle" />
            </div>
        );
    }
};



