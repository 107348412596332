import { audioStrHelper } from "./audioStrHelper";
import { backgroundQuestionOptionsObj } from "../../objects/Screener/backgroundQuestionOptionsObj";
import { backgroundQuestionTextObj } from "../../objects/Screener/backgroundQuestionTextObj";

export const generateBackgroundQuestionHelper = (userType, language, questionId) => {
    //temp - to test
    // userType = 3;

    //ultimate obj that will populate the react components
    let resultObj = {};

    let questionTextId = questionId + "S";
    //if userType is caregiver
    if (userType === 3) {
        questionTextId = questionId + "C";
    }

    resultObj.questionText = backgroundQuestionTextObj[questionTextId][language];
    resultObj.englishQuestionText = backgroundQuestionTextObj[questionTextId]["English"];
    resultObj.questionAudio = audioStrHelper(userType, language, questionId);
    //adding audio string to options
    if (questionId !== "4" && questionId !== "5" && questionId !== "7") {
        resultObj.options = backgroundQuestionOptionsObj[questionTextId].map((o) => {
            o.audio = audioStrHelper(userType, language, o["id"]);
            // console.log(o);
            o.text = backgroundQuestionTextObj[o["id"]][language];
            o.englishText = backgroundQuestionTextObj[o["id"]]["English"];
            // console.log("backgroundQuestionOptionsObj -->", o)
            return o;
        });
    }

    return resultObj;
};
