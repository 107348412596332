import React from "react";
import { MyAnswerAudioButton } from "./MyAnswerAudioButton";
import { MyRadio } from "./MyRadio";

export const ScreenerRadio = ({
  language,
  text,
  englishText,
  audio,
  altName
}) => {

  let name = "formData";
  if (altName) {
    name = altName;
  }

  return (
    <div style={{ paddingTop: "13px", paddingBottom: "13px" }}>
      {language === "Arabic" && (
        <MyAnswerAudioButton audio={audio} />
      )}
      <MyRadio
        language={language}
        name={name}
        type="radio"
        value={englishText}
        as={MyRadio}
        label={text}
      />
      {language !== "Arabic" && (
        <MyAnswerAudioButton audio={audio} />
      )}
    </div>
  );
};
