import React, { useState, useEffect, Fragment } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Tooltip from '@mui/material/Tooltip';
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { AbeeStatus } from "./AbeeStatus";
import { DrawerHeader } from "./DrawerHeader";
import clsx from "clsx";

export const MenuDrawer = ({ status, open, classes, handleDrawerClose }) => {
    return (
        <Drawer
            open={open}
            variant="persistent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                })
            }}
            style={{
                position: "relative",
                zIndex: 1,
            }}
        >
            <DrawerHeader style={{ backgroundColor: "#1E272E" }}>
                {/* TODO - make screen size dynamic */}
                <Tooltip title="Questions">
                    <Typography noWrap align="left" style={{ fontSize: "1.2em", paddingRight: "2.5em", color: "#e6ecf2", letterSpacing: "0.1em" }}>
                        Preguntas
                    </Typography>
                </Tooltip>
                <IconButton onClick={handleDrawerClose} style={{ color: "#e6ecf2" }}>
                    {/* use when we are doing arabic and we add theme direction */}
                    {/* {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />} */}
                    <ChevronLeftIcon />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <AbeeStatus status={status} />
            <Divider />
        </Drawer>
    );
}