import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { Background } from "./pages/Background";
import { ScreenerConfirm } from "./pages/ScreenerConfirm";
import { PasswordReset } from "./pages/PasswordReset";
import { SetPassword } from "./pages/SetPassword";
import { Literacy } from "./pages/Literacy";
import { Oauth } from "./pages/Oauth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Numeracy } from "./pages/Numeracy";
import { api } from "./helpers/axios";

export const AllRoutes = () => {

  const [status, setStatus] = useState(null);
  const accessToken = window.localStorage.getItem("iat");

  useEffect(() => {
    const getData = async () => {
      api.get(`/student-app/status`, {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${accessToken}`
        }
      })
        .then(res => {
          if (res.status === 200 && res.data && res.data.message && res.data.message.answers && res.data.message.answers.length > 0) {
            setStatus(res.data.message.answers);
          }
        })
        // .then(() => {
        //     setLoading(false);
        // })
        .catch((error) => {
          //TODO: figure out how to handle this gracefully
          console.log(`Error: ${error}`);
        });
    };

    getData();
  }, [accessToken]);

  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/literacy/:id" element={<Literacy />} />
          <Route path="/numeracy/:id" element={<Numeracy />} />
          <Route path="/confirm" element={<ScreenerConfirm status={status} />} />
          <Route path="/background/:id" element={<Background />} />
          <Route path="/cleveroauth/*" element={<Oauth />} />
          <Route path="/passwordreset" element={<PasswordReset />} />
          <Route path="/setpassword/:userId/:token" element={<SetPassword />} />
        </Routes>
        <ToastContainer position="top-center" />
      </div>
    </BrowserRouter>
  );
};