import React from "react";
import VolumeUpOutlinedIcon from "@material-ui/icons/VolumeUpOutlined";

export const MyAnswerAudioButton = ({ audio }) => {
  if (!audio) {
    return;
  } else {
    return (
      <VolumeUpOutlinedIcon
        fontSize="small"
        onClick={(e) => {
          e.preventDefault();
          let sound = new Audio(audio);
          sound.play();
        }}
        style={{ cursor: "pointer" }}
      ></VolumeUpOutlinedIcon>
    );
  }
};
