import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import { useParams } from "react-router-dom";
import { abeeQuestionOrder } from "../../helpers/abeeQuestionOrder";

const ListItemLink = (props) => {
  return <ListItem component="a" {...props} />;
};

export const AbeeStatus = ({ status }) => {
  const abeeType = window.localStorage.getItem("abeeType");
  const grade = window.localStorage.getItem("grade");
  let { id } = useParams();

  let readingQuestion;

  let noMissedSchool;

  if (status) {
    noMissedSchool = status.find(o => {
      if (o && o.questionId === '2' && o.answerText === 'No') {
        return o;
      }
    });

    readingQuestion = status.find(o => {
      if (o && o.questionId === '10' && o.answerText === 'No') {
        return o;
      }
    });
  }

  return (
    <div>
      {abeeQuestionOrder(abeeType, grade).map((q, i) => {

        let answered;

        if (status) {
          answered = status.find(i => {
            if (i.questionId === q) {
              return i
            }
          })
        }

        //greens out questions 2B and 3 so as not to confuse the student
        if (noMissedSchool && (q === "2B" || q === "3")) {
          answered = true;
        };

        //figures out which question the user is on before the id is remapped to the menu question number
        let activeQuestionColor = "";
        if (id === q) {
          activeQuestionColor = "#1976D2";
        }

        // set menu navigation link from user click
        let href = `/background/${q}`;
        if (q[0] === "l") {
          href = `/literacy/${q}`
        } else if (q[0] === "n") {
          href = `/numeracy/${q}`
        }

        //TODO: figure out a more elegant way to do this - remapping question id to order displayed on menu bar
        if (q === "1") {
          q = "3"
        } else if (q === "2") {
          q = "4"
        }
        else if (q === "2B") {
          q = "5"
        }
        else if (q === "104") {
          q = "2"
        } else if (q === "59209") {
          q = "1"
        } else if (q === "3") {
          q = "6"
        } else if (q === "4") {
          q = "7"
        } else if (q === "5") {
          q = "8"
        } else if (q === "6") {
          q = "9"
        } else if (q === "7") {
          q = "10"
        } else if (q === "8") {
          q = "11"
        } else if (q === "9") {
          q = "12"
        } else if (q === "lit5-78363-1" || q === "lit6-77236-1" || q === "lit7-12998-1" || q === "lit8-10048-1") {
          q = "Literatura 1"
        }
        else if (q === "lit5-78363-2" || q === "lit6-77236-2" || q === "lit7-12998-2" || q === "lit8-10048-2") {
          q = "Literatura 2"
        }
        else if (q === "lit5-78363-3" || q === "lit6-77236-3" || q === "lit7-12998-3" || q === "lit8-10048-3") {
          q = "Literatura 3"
        }
        else if (q === "lit5-78363-4" || q === "lit6-77236-4" || q === "lit7-12998-4" || q === "lit8-10048-4") {
          q = "Literatura 4"
        } else if (q === "num5-21389" || q === "num6-45703" || q === "num7-45704" || q === "num8-45705") {
          q = "Matemáticas 1"
        } else if (q === "num5-00740" || q === "num6-49147" || q === "num7-49148" || q === "num8-49149") {
          q = "Matemáticas 2"
        } else if (q === "num5-86340" || q === "num6-83265" || q === "num7-83267" || q === "num8-83268") {
          q = "Matemáticas 3"
        } else if (q === "num5-15811" || q === "num6-54571" || q === "num7-54572" || q === "num8-54573") {
          q = "Matemáticas 4"
        } else if (q === "num5-25484" || q === "num6-43012" || q === "num7-43013" || q === "num8-43014") {
          q = "Matemáticas 5"
        } else if (q === "num5-80549" || q === "num6-19231" || q === "num7-19232" || q === "num8-19233") {
          q = "Matemáticas 6"
        } else if (q === "num5-52838" || q === "num6-46851" || q === "num7-46852" || q === "num8-46853") {
          q = "Matemáticas 7"
        } else if (q === "num5-40806" || q === "num6-76575" || q === "num7-76576" || q === "num8-76577") {
          q = "Matemáticas 8"
        }

        return (
          <ListItemLink href={href} style={{ backgroundColor: activeQuestionColor }}>
            <ListItem key={i}>
              <ListItemIcon>
                {!answered && (
                  // For some weird reason, this is the default MUI colors not the ones from the custom theme. This primary is a blue.
                  <RadioButtonUncheckedIcon style={{ color: "#92969B" }} />
                )}
                {answered && (
                  <CheckCircleTwoToneIcon style={{ color: "#34B84C" }} />
                )}
              </ListItemIcon>
              {!answered && (
                <ListItemText
                  primary={<Typography style={{ color: "#e6ecf2" }} type="body2">{q}</Typography>}
                />
              )}
              {answered && (
                <ListItemText
                  primary={<Typography style={{ color: "#e6ecf2" }} type="body2">{q}</Typography>}
                />
              )}

            </ListItem>
          </ListItemLink>
        )
      })}
    </div>)
};

