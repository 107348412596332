import Grid from '@mui/material/Grid';
import VolumeUpTwoToneIcon from "@material-ui/icons/VolumeUpTwoTone";
import VolumeUpOutlinedIcon from "@material-ui/icons/VolumeUpOutlined";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { Button } from "@material-ui/core";
//components
import onSubmitForm from "../../helpers/Screener/onSubmitForm";
import { useStyles } from "../../styles/styles";
import { MyRadio } from '../AnswerFields/MyRadio';
import { Typography } from '@mui/material';
import { ButtonBlock } from '../Screener/buttonBlock';
import { numeracyQuestionsV1 } from "../../objects/numeracyQuestionsV1"

export const NumeracyItem = ({ questionId, formValues }) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const tempData = {
        formData: {}
    };

    const getData = () => formValues || tempData;
    //temp
    const language = "Spanish"
    //temp
    const popup = null;

    return (
        <Formik
            enableReinitialize={true}
            initialValues={getData()}
            onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);
                await onSubmitForm(
                    data,
                    navigate,
                    questionId,
                    language,
                    popup
                );
                setSubmitting(false);
            }}
        >
            <Form>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} style={{ paddingLeft: "10em", paddingRight: "10em" }}>
                        {(numeracyQuestionsV1[questionId].equationURL) && (
                            <div style={{ marginTop: "1em" }}>
                                <Typography style={{ fontSize: 20 }}>{"Selecciona la opción que resuelve la siguiente expresión."}</Typography>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        //temp
                                        let sound = new Audio("https://storage.googleapis.com/inlier-audio/numeracy/spanish/math-expression-instructions-spanish.mp3");
                                        sound.play();
                                    }}
                                    style={{ textTransform: "none", color: "#1876D0", fontSize: 20, }}
                                >
                                    <VolumeUpTwoToneIcon />
                                </Button>
                                <div style={{ marginTop: "2em" }}>
                                    <img
                                        // style={{ height: "11.5em", width: "17.5em", borderRadius: "20px", padding: "0.5em", filter: "drop-shadow(2px 2px 2px gray)" }}
                                        src={numeracyQuestionsV1[questionId].equationURL}
                                        srcSet={numeracyQuestionsV1[questionId].equationURL}
                                    />
                                </div>
                            </div>
                        )}
                        {(numeracyQuestionsV1[questionId].mathExpressionInstructions) && (
                            <div style={{ marginTop: "1em" }}>
                                <Typography style={{ fontSize: 20 }}>{"Selecciona la opción que resuelve la siguiente expresión."}</Typography>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        //temp
                                        let sound = new Audio("https://storage.googleapis.com/inlier-audio/numeracy/spanish/math-expression-instructions-spanish.mp3");
                                        sound.play();
                                    }}
                                    style={{ textTransform: "none", color: "#1876D0", fontSize: 20, }}
                                >
                                    <VolumeUpTwoToneIcon />
                                </Button>
                            </div>
                        )}
                        {(numeracyQuestionsV1[questionId].body) && (
                            <div style={{ marginTop: "1em" }}>
                                <Typography style={{ fontSize: 20 }}>{numeracyQuestionsV1[questionId].body}</Typography>
                                {(numeracyQuestionsV1[questionId].audio) && (
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            //temp
                                            let sound = new Audio(numeracyQuestionsV1[questionId].audio);
                                            sound.play();
                                        }}
                                        style={{ textTransform: "none", color: "#1876D0", fontSize: 20, }}
                                    >
                                        <VolumeUpTwoToneIcon />
                                    </Button>
                                )}
                            </div>
                        )}
                        {/* TODO: figure out a better solution to this */}
                        {(numeracyQuestionsV1[questionId].body2) && (
                            <div style={{ marginTop: "1em" }}>
                                <Typography style={{ fontSize: 20 }}>{numeracyQuestionsV1[questionId].body2}</Typography>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs style={{ marginTop: "1em" }}>
                        {numeracyQuestionsV1[questionId].options.map((option) => (
                            <div style={{ padding: "0.5em" }}>
                                {(option.equationURL) && (
                                    <div style={{ textAlign: "left" }}>
                                        <MyRadio
                                            language={"Spanish"}
                                            name={"formData"}
                                            type="radio"
                                            value={option.equationURL}
                                            as={MyRadio}
                                            fontSize={20}
                                        />
                                        <img
                                            src={option.equationURL}
                                            srcSet={option.equationURL}
                                        />
                                    </div>)}
                                {(option.body && !option.audio) && (
                                    <div style={{ textAlign: "left" }}>
                                        <MyRadio
                                            language={"Spanish"}
                                            name={"formData"}
                                            type="radio"
                                            value={option.body}
                                            as={MyRadio}
                                            label={option.body}
                                            fontSize={20}
                                        />
                                    </div>)}
                                {(option.body && option.audio) && (
                                    <div style={{ textAlign: "left" }}>
                                        <MyRadio
                                            language={"Spanish"}
                                            name={"formData"}
                                            type="radio"
                                            value={option.body}
                                            as={MyRadio}
                                            label={option.body}
                                            fontSize={20}
                                        />
                                        <VolumeUpOutlinedIcon
                                            fontSize="small"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                let sound = new Audio(option.audio);
                                                sound.play();
                                            }}
                                            style={{ cursor: "pointer" }}
                                        ></VolumeUpOutlinedIcon>
                                    </div>)}
                            </div>
                        ))}
                    </Grid>
                </Grid>
                <ButtonBlock language={language} />
            </Form>
        </Formik >
    )
}

