import React, { Fragment, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Button } from "@material-ui/core";

const EnglishPopup = ({
  question,
  options,
  stateChanger
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <Button
        style={{
          cursor: "pointer",
          color: "grey",
          float: "right"
        }}
        onClick={(e) => {
          e.preventDefault();
          stateChanger(true);
          setOpen(true);
        }}
      >
        English
      </Button>
      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <DialogTitle>{question}</DialogTitle>
        <List>
          {options &&
            options.length > 0 &&
            options.map((arr) => <ListItem>{arr.englishText}</ListItem>)}
        </List>
      </Dialog>
    </Fragment>
  );
};

export default EnglishPopup;
