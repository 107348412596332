import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";


export const Home = () => {
  const navigate = useNavigate();
  const accessToken = window.localStorage.getItem("iat");

  useEffect(
    () => {
      if (!accessToken) {
        navigate(`/login`);
      } else {
        navigate(`/login`);
      }
    },
    [accessToken, navigate]
  );

  return <div />;
};
