import React from "react";
// import { BallTriangle } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import { useStyles } from "../styles/styles";
import { AppBarAndDrawer } from "../components/AppNavBar/AppBarAndDrawer";
import { Button } from "@material-ui/core";
import { api } from "../helpers/axios";

export const ScreenerConfirm = ({ status }) => {
  const accessToken = window.localStorage.getItem("iat");
  const classes = useStyles();
  const { glowing } = useStyles();
  const navigate = useNavigate();

  const logout = async () => {
    api
      .post(
        `/auth/logout`,
        {},
        {
          withCredentials: true,
          headers: {
            // "Content-Type": "application/json",
            authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then(async response => {
        if (response && response.data) {
          // console.log("reponse data", response.data);
          window.localStorage.clear();
          return navigate("/");
        }
      })
      .catch(err => {
        console.error(err);
      });
    navigate("/");
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBarAndDrawer status={status} />
      <main className={classes.content}>
        <div>
          <Container maxWidth="xl" className={classes.screenerContainer}>
            <CheckCircleTwoToneIcon style={{ fontSize: 50, color: "green" }} />
            <div className={classes.confirmButton}>
              <Button
                onClick={logout}
                variant="contained"
                color="primary"
                type="submit"
                className={`${glowing}`}
              >
                Logout
              </Button>
            </div>
          </Container>
        </div>
      </main>
    </div>
  );
};
