import React from "react";
import VolumeUpTwoToneIcon from "@material-ui/icons/VolumeUpTwoTone";
import { Button } from "@material-ui/core";
import { useStyles } from "../../styles/styles";

export const QuestionText = ({
  text, questionAudio,
}) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.questionText}>{text}</div>
      <Button
        onClick={(e) => {
          e.preventDefault();
          let sound = new Audio(questionAudio);
          sound.play();
        }}
      >
        <VolumeUpTwoToneIcon className={classes.questionIcon} />
      </Button>
    </div>
  );
};
