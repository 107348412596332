//options per questionTextId
export const backgroundQuestionOptionsObj = {
    "59209S": [
        {
            id: "59209a",
            type: "checkbox"
        },
        {
            id: "59209b",
            type: "checkbox"
        },
        {
            id: "59209c",
            type: "checkbox"
        },
        {
            id: "59209d",
            type: "checkbox"
        },
        {
            id: "59209e",
            type: "checkbox"
        },
        {
            id: "59209f",
            type: "checkbox"
        },
        {
            id: "59209g",
            type: "checkbox"
        },
        {
            id: "59209h",
            type: "checkbox"
        },
        {
            id: "59209i",
            type: "checkbox"
        },
        {
            id: "59209j",
            type: "checkbox"
        },
        {
            id: "59209k",
            type: "checkbox"
        },
        {
            id: "59209l",
            type: "checkbox"
        },
        {
            id: "59209m",
            type: "checkbox"
        }
    ],
    "59209C": [
        {
            id: "59209a",
            type: "checkbox"
        },
        {
            id: "59209b",
            type: "checkbox"
        },
        {
            id: "59209c",
            type: "checkbox"
        },
        {
            id: "59209d",
            type: "checkbox"
        },
        {
            id: "59209e",
            type: "checkbox"
        },
        {
            id: "59209f",
            type: "checkbox"
        },
        {
            id: "59209g",
            type: "checkbox"
        },
        {
            id: "59209h",
            type: "checkbox"
        },
        {
            id: "59209i",
            type: "checkbox"
        },
        {
            id: "59209j",
            type: "checkbox"
        },
        {
            id: "59209k",
            type: "checkbox"
        },
        {
            id: "59209l",
            type: "checkbox"
        },
        {
            id: "59209m",
            type: "checkbox"
        }
    ],
    "1S": [
        {
            id: "Y",
            type: "radio"
        },
        {
            id: "N",
            type: "radio"
        }
    ],
    "1C": [
        {
            id: "Y",
            type: "radio"
        },
        {
            id: "N",
            type: "radio"
        }
    ],
    "2S": [
        {
            id: "2a",
            type: "radio"
        },
        {
            id: "2b",
            type: "radio"
        },
        {
            id: "N",
            type: "radio"
        }
    ],
    "2C": [
        {
            id: "2a",
            type: "radio"
        },
        {
            id: "2b",
            type: "radio"
        },
        {
            id: "N",
            type: "radio"
        }
    ],
    "2BS": [
        {
            id: "Y",
            type: "radio"
        },
        {
            id: "N",
            type: "radio"
        }
    ],
    "2BC": [
        {
            id: "Y",
            type: "radio"
        },
        {
            id: "N",
            type: "radio"
        }
    ],
    "3S": [
        {
            id: "3aS",
            type: "checkbox"
        },
        {
            id: "3bS",
            type: "checkbox"
        },
        {
            id: "3cS",
            type: "checkbox"
        },
        {
            id: "3dS",
            type: "checkbox"
        },
        {
            id: "3eS",
            type: "checkbox"
        },
        {
            id: "3fS",
            type: "checkbox"
        },
        {
            id: "3gS",
            type: "checkbox"
        },
        {
            id: "3hS",
            type: "checkbox"
        },
        {
            id: "3iS",
            type: "checkbox"
        }
    ],
    "3C": [
        {
            id: "3aC",
            type: "checkbox"
        },
        {
            id: "3bC",
            type: "checkbox"
        },
        {
            id: "3cC",
            type: "checkbox"
        },
        {
            id: "3dC",
            type: "checkbox"
        },
        {
            id: "3eC",
            type: "checkbox"
        },
        {
            id: "3fC",
            type: "checkbox"
        },
        {
            id: "3gC",
            type: "checkbox"
        },
        {
            id: "3hC",
            type: "checkbox"
        },
        {
            id: "3iC",
            type: "checkbox"
        }
    ],
    "6S": [
        {
            id: "Y",
            type: "radio"
        },
        {
            id: "N",
            type: "radio"
        }
    ],
    "6C": [
        {
            id: "Y",
            type: "radio"
        },
        {
            id: "N",
            type: "radio"
        }
    ],
    "8S": [
        {
            id: "8aS",
            type: "radio",
            altName: "formData.classGender"
        },
        {
            id: "8bS",
            type: "radio",
            altName: "formData.classGender"
        },
        {
            id: "8jS",
            type: "radio",
            altName: "formData.classGender"
        },
        {
            id: "8dS",
            type: "radio",
            altName: "formData.classSize"
        },
        {
            id: "8eS",
            type: "radio",
            altName: "formData.classSize"
        },
        {
            id: "8cS",
            type: "checkbox",
            altName: "formData.other"
        },
        {
            id: "8fS",
            type: "checkbox",
            altName: "formData.other"
        },
        {
            id: "8gS",
            type: "checkbox",
            altName: "formData.other"
        },
        {
            id: "8hS",
            type: "checkbox",
            altName: "formData.other"
        },
        {
            id: "8iS",
            type: "checkbox",
            altName: "formData.other"
        },
        {
            id: "None",
            type: "checkbox",
            altName: "formData.other"
        }
    ],
    "8C": [
        {
            id: "8aC",
            type: "radio",
            altName: "formData.classGender"
        },
        {
            id: "8bC",
            type: "radio",
            altName: "formData.classGender"
        },
        {
            id: "8jC",
            type: "radio",
            altName: "formData.classGender"
        },
        {
            id: "8dC",
            type: "radio",
            altName: "formData.classSize"
        },
        {
            id: "8eC",
            type: "radio",
            altName: "formData.classSize"
        },
        {
            id: "8cC",
            type: "checkbox",
            altName: "formData.other"
        },
        {
            id: "8fC",
            type: "checkbox",
            altName: "formData.other"
        },
        {
            id: "8gC",
            type: "checkbox",
            altName: "formData.other"
        },
        {
            id: "8hC",
            type: "checkbox",
            altName: "formData.other"
        },
        {
            id: "8iC",
            type: "checkbox",
            altName: "formData.other"
        },
        {
            id: "None",
            type: "checkbox",
            altName: "formData.other"
        }
    ],
    "9S": [
        {
            id: "9aS",
            type: "checkbox"
        },
        {
            id: "9bS",
            type: "checkbox"
        },
        {
            id: "9cS",
            type: "checkbox"
        },
        {
            id: "None",
            type: "checkbox"
        },
    ],
    "9C": [
        {
            id: "9aC",
            type: "checkbox"
        },
        {
            id: "9bC",
            type: "checkbox"
        },
        {
            id: "9cC",
            type: "checkbox"
        },
        {
            id: "None",
            type: "checkbox"
        }
    ],
    "104S": [
        {
            id: "104aS",
            type: "checkbox"
        },
        {
            id: "104bS",
            type: "checkbox"
        },
        {
            id: "104cS",
            type: "checkbox"
        },
        {
            id: "104dS",
            type: "checkbox"
        },
        {
            id: "104eS",
            type: "checkbox"
        },
        {
            id: "None",
            type: "checkbox"
        },
    ],
    "104C": [
        {
            id: "104aC",
            type: "checkbox"
        },
        {
            id: "104bC",
            type: "checkbox"
        },
        {
            id: "104cC",
            type: "checkbox"
        },
        {
            id: "104dC",
            type: "checkbox"
        },
        {
            id: "104eC",
            type: "checkbox"
        },
        {
            id: "None",
            type: "checkbox"
        },
    ],
};
