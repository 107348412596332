import { useMediaQuery } from "react-responsive"

export const useDesktopMediaQuery = () =>
    useMediaQuery({ query: "(min-width: 1280px)" })

export const useTabletAndBelowMediaQuery = () =>
    useMediaQuery({ query: "(max-width: 1279px)" })

export const Desktop = ({ children }) => {
    const isDesktop = useDesktopMediaQuery()

    return isDesktop ? children : null
}