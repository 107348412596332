import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import VolumeUpTwoToneIcon from "@material-ui/icons/VolumeUpTwoTone";
import VolumeUpOutlinedIcon from "@material-ui/icons/VolumeUpOutlined";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { Button } from "@material-ui/core";
//components
import onSubmitForm from "../../helpers/Screener/onSubmitForm";
import Popper from '@mui/material/Popper';
import { MyRadio } from '../AnswerFields/MyRadio';
import { Typography, Card } from '@mui/material';
import { ButtonBlock } from '../Screener/buttonBlock';
import { literacyPassagesV1 } from "../../objects/literacyPassagesV1"

export const LiteracyItem = ({ questionId, formValues }) => {
    const grade = window.localStorage.getItem("grade");
    const navigate = useNavigate();
    const tempData = {
        formData: {}
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const getData = () => formValues || tempData;
    //temp
    const language = "Spanish"
    //temp
    const popup = null;

    return (
        <Formik
            enableReinitialize={true}
            initialValues={getData()}
            onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);
                await onSubmitForm(
                    data,
                    navigate,
                    questionId,
                    language,
                    popup
                );
                setSubmitting(false);
            }}
        >
            <Form>
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        setAnchorEl(anchorEl ? null : e.currentTarget);
                    }}
                    style={{ float: "right", textTransform: "none", color: "#1876D0", fontSize: 20, }}
                >
                    Ayuda
                </Button>
                <Popper id={id} open={open} anchorEl={anchorEl} placement={'left'} style={{ textAlign: "center" }}>
                    <Card ellevation={4} sx={{ bgcolor: '#E5E8E9', padding: "0.5em" }}>
                        <Typography style={{ fontSize: 16, lineHeight: "1.5" }}>{"Por favor lee el pasaje y responde la pregunta."}</Typography>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                //temp
                                let sound = new Audio("https://storage.googleapis.com/inlier-audio/literacy/spanish/literacy-spanish-passage-instructions.mp3");
                                sound.play();
                            }}
                            style={{ textTransform: "none", color: "#1876D0", fontSize: 20, }}
                        >
                            <VolumeUpTwoToneIcon />
                        </Button>
                    </Card>
                </Popper>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={3}
                >
                    <Grid item xs={10}>
                        <Typography style={{ textAlign: "left", fontSize: 20, lineHeight: "2" }}>{literacyPassagesV1[grade].passage.title}</Typography>
                        <Typography style={{ textAlign: "left", fontSize: 20, lineHeight: "1.5" }}>{literacyPassagesV1[grade].passage.body}
                            {(literacyPassagesV1[grade].passage.audio && literacyPassagesV1[grade].passage.questions[questionId].audio) && (
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        let sound = new Audio(literacyPassagesV1[grade].passage.audio);
                                        sound.play();
                                    }}
                                    style={{ textTransform: "none", color: "#1876D0", marginLeft: "1em" }}
                                >
                                    <VolumeUpTwoToneIcon />
                                </Button>
                            )}</Typography>
                    </Grid>
                    <Grid alignItems="flex-start" direction="row" container item xs={10} >
                        <Grid item xs={12} style={{ border: "solid #DBE3E6", padding: "1em", borderRadius: "10px" }}>
                            <Typography style={{ textAlign: "left", fontSize: 20, lineHeight: "1.5", marginBottom: "1em" }}>{literacyPassagesV1[grade].passage.questions[questionId].body}
                                {(literacyPassagesV1[grade].passage.questions[questionId].audio) && (
                                    <Button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            let sound = new Audio(literacyPassagesV1[grade].passage.questions[questionId].audio);
                                            sound.play();
                                        }}
                                        style={{ textTransform: "none", color: "#1876D0", marginLeft: "1em" }}
                                    >
                                        <VolumeUpTwoToneIcon />
                                    </Button>
                                )}
                            </Typography>
                            {literacyPassagesV1[grade].passage.questions[questionId].options.map((option) => (
                                <div style={{ textAlign: "left" }}>
                                    <MyRadio
                                        language={"Spanish"}
                                        name={"formData"}
                                        type="radio"
                                        value={option.body}
                                        as={MyRadio}
                                        label={option.body}
                                        fontSize={20}
                                    />
                                    {(option.audio) && (
                                        <VolumeUpOutlinedIcon
                                            fontSize="small"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                let sound = new Audio(option.audio);
                                                sound.play();
                                            }}
                                            style={{ cursor: "pointer" }}
                                        ></VolumeUpOutlinedIcon>
                                    )}
                                </div>
                            ))}
                        </Grid>
                        <ButtonBlock language={language} />
                    </Grid>
                </Grid>
            </Form>
        </Formik >
    )
}

