import React, { useState, useEffect, Fragment } from "react";
import { NavLoginButton } from "./NavLoginButton";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { MenuDrawer } from "./MenuDrawer";
import { useStyles } from "../../styles/styles";
import { AppBar } from "./AppBar";
import { useTabletAndBelowMediaQuery } from "../../helpers/usingMediaQuery";

export const AppBarAndDrawer = ({ status }) => {

  const accessToken = window.localStorage.getItem("iat");
  const classes = useStyles();
  const [open, setOpen] = useState();
  const isTabletAndBelow = useTabletAndBelowMediaQuery();

  useEffect(() => {
    if (isTabletAndBelow) {
      setOpen(false)
    } else {
      setOpen(JSON.parse(window.localStorage.getItem('open')));
    }

  }, [setOpen, isTabletAndBelow]);

  useEffect(() => {
    if (open === null && !isTabletAndBelow) {
      setOpen(true)
      window.localStorage.setItem('open', open);
    } else if (open === null && isTabletAndBelow) {
      setOpen(false)
      window.localStorage.setItem('open', open);
    }
    window.localStorage.setItem('open', open);
  }, [open, isTabletAndBelow]);


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Fragment className={classes.root}>
      <CssBaseline />
      <AppBar
        elevation={2}
        position="fixed"
        open={open}
        className={classes.appBar}
        style={{ background: "#1E272E" }}
      >
        <Toolbar>
          {accessToken && (
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={classes.menuButton}
            >
              <MenuIcon style={{ color: "#e6ecf2" }} />
            </IconButton>
          )}
          <Typography variant="h6" noWrap style={{
            fontSize: 26, letterSpacing: "0.1em", background: "-webkit-linear-gradient(45deg, #1876D0 30%, #156abb 90%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontFamily: "American Typewriter, serif"
          }}>
            Abee
          </Typography>
          <section className={classes.rightToolbar}>
            <NavLoginButton />
          </section>
        </Toolbar>
      </AppBar>
      {accessToken && (
        <MenuDrawer
          status={status}
          open={open}
          classes={classes}
          handleDrawerClose={handleDrawerClose} />
      )}
    </Fragment>
  );
};
