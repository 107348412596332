//modules
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Grid,
    Paper,
    TextField,
    Button,
    Typography,
    Link,
    Divider
} from "@material-ui/core";
import { toast } from "react-toastify";
//components
import { api } from "../helpers/axios";
import { useStyles } from "../styles/styles";
import { validateEmail } from "../helpers/validateEmail";
//images
import smallLogo from "../styles/images/small-logo.png";

export const PasswordReset = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [success, setSuccess] = useState(false);
    const [errorText, setErrorText] = useState('');

    useEffect(() => {
        if (username && validateEmail(email)) {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }
    },
        [username, email, setButtonDisabled]
    );

    return (
        <Grid>
            <Paper elevation={3} className={classes.loginPaper}>
                <Grid align="center">
                    <img src={smallLogo} className={classes.loginLogo} alt="inlier logo" />
                    <Divider />
                    <Typography component="h3" className={classes.loginHeaderTypography}>
                        Password Reset
                    </Typography>

                </Grid>
                {!success && (<div>
                    <Typography component="h3" className={classes.loginHeaderTypography}>
                        Please note that if you signed up with Clever, your login credentials must be troubleshooted on <a target="_blank" rel="noreferrer" href="https://support.clever.com/hc/s/articles/115002717307?language=en_US">their system</a>. Otherwise, proceed below.
                    </Typography>
                    <Typography>
                        Username
                    </Typography>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        onChange={e => {
                            setUsername(e.target.value);
                        }}
                    />
                    <Typography style={{ paddingTop: "1.5em" }}>
                        The recovery email associated with the above username, typically an teacher's or administrator's email.
                    </Typography>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={e => {
                            setEmail(e.target.value);
                            if (email && validateEmail(email)) {
                                setErrorText("")
                            }
                        }}
                        onBlur={e => {
                            if (email && !validateEmail(email)) {
                                setErrorText('Please check your email format.')
                            } else if (email && validateEmail(email)) {
                                setErrorText("")
                            }
                        }}
                        error={errorText.length === 0 ? false : true}
                        helperText={errorText}
                    />
                    <Button
                        disabled={buttonDisabled}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={async e => {
                            e.preventDefault();
                            //axios call to login route
                            api
                                .post(
                                    `/auth/passwordreset`,
                                    { username, email },
                                    {
                                        withCredentials: true,
                                        headers: {
                                            // "Content-Type": "application/json"
                                        }
                                    }
                                )
                                .then(async response => {
                                    if (response && response.data && response.data.status === 200) {
                                        setSuccess(true);
                                    }
                                })
                                .catch(err => {
                                    toast.error(
                                        `No account found for the username and email entered.`
                                    );
                                });
                        }}
                    >
                        Reset Password
                    </Button>
                </div>)}
                {success && (
                    <div>
                        Your password recovery link has been sent to the email associated with the account. Please allow up to 15 minutes for delivery and check your spam folder.
                        If email is not delivered, please contact us.
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={async e => {
                                e.preventDefault();
                                navigate("/")
                            }}
                        >
                            Return Home
                        </Button>
                    </div>
                )}
                <div className={classes.loginHelpLinks}>
                    Don't remember your username or password? Click "Request Help" to contact us.
                    <div>
                        <Link href="https://www.inlierlearning.com/requestapphelp" target="_blank" rel="noreferrer" >
                            Request Help
                        </Link>
                    </div>
                </div>
            </Paper>
        </Grid>
    );
};
