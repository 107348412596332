export const nativeLanguageId = {
  Amharic: 1,
  Arabic: 2,
  Bengali: 3,
  Burmese: 4,
  ChineseSimplified: 5,
  ChineseTraditional: 6,
  English: 7,
  French: 8,
  HaitianCreole: 9,
  Nepali: 10,
  Portuguese: 11,
  Russian: 12,
  Somali: 13,
  Spanish: 14,
  Swahili: 15,
  Urdu: 16,
  Vietnamese: 17,
  Mam: 18
};
export const idToLanguage = {
  1: "Amharic",
  2: "Arabic",
  3: "Bengali",
  4: "Burmese",
  5: "ChineseSimplified",
  6: "ChineseTraditional",
  7: "English",
  8: "French",
  9: "HaitianCreole", //No STT
  10: "Nepali",
  11: "Portuguese",
  12: "Russian",
  13: "Somali", //No STT
  14: "Spanish",
  15: "Swahili",
  16: "Urdu",
  17: "Vietnamese",
  18: "Mam"
};
