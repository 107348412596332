import React, { useState, Fragment } from "react";
import VolumeUpOutlinedIcon from "@material-ui/icons/VolumeUpOutlined";
import EnglishPopup from "./EnglishPopup";
import {
  answeredRequired,
  answeredRequiredAudio
} from "../../objects/Screener/answerRequired";

const ErrorBlock = ({ language, errors, touched }) => {
  //english popup
  const [setPopup] = useState(false);

  return (
    <Fragment>
      {errors.formData && touched.formData ? (
        <div
          style={{
            color: "red"
          }}
        >
          {language === "Arabic" && (
            <span style={{ paddingRight: "0.5em" }}>
              <VolumeUpOutlinedIcon
                fontSize="small"
                onClick={(e) => {
                  e.preventDefault();
                  let sound = new Audio(answeredRequiredAudio[language]);
                  sound.play();
                }}
                style={{ cursor: "pointer" }}
              ></VolumeUpOutlinedIcon>
            </span>
          )}
          {answeredRequired[language]}
          {language !== "Arabic" && (
            <span style={{ paddingLeft: "0.5em" }}>
              <VolumeUpOutlinedIcon
                fontSize="small"
                onClick={(e) => {
                  e.preventDefault();
                  let sound = new Audio(answeredRequiredAudio[language]);
                  sound.play();
                }}
                style={{ cursor: "pointer" }}
              ></VolumeUpOutlinedIcon>
            </span>
          )}
          <EnglishPopup
            userType={1}
            studentQuestion={errors.formData}
            contactQuestion={errors.formData}
            stateChanger={setPopup}
          />
        </div>
      ) : null}
    </Fragment>
  );
};

export default ErrorBlock;
