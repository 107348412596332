import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppBarAndDrawer } from "../components/AppNavBar/AppBarAndDrawer";
import { api } from "../helpers/axios";
import { toast } from "react-toastify";

export const Oauth = () => {
    const [searchParams] = useSearchParams();
    const oauthCode = searchParams.get("code");
    const stateParam = searchParams.get("state");
    const scope = searchParams.get("scope")
    const navigate = useNavigate();

    useEffect(
        () => {
            if (oauthCode && (stateParam || scope)) {
                const reqBody = { code: oauthCode, state: stateParam, scope: scope };
                api
                    .post(`/auth/clever`, reqBody, {
                        headers: {
                            Accept: "application/json",
                            // "Content-Type": "application/json"
                        },
                        withCredentials: true
                    })
                    .then(async x => {
                        if ((stateParam && x.data.message.state === stateParam) || (scope && x && x.data && x.data.message && x.data.message.user && x.data.message.user.username && x.data.message.accessToken)) {
                            window.localStorage.setItem(
                                "iat",
                                x.data.message.accessToken
                            );
                            window.localStorage.setItem(
                                "iun",
                                x.data.message.user.username
                            );
                            return navigate("/screener/1");
                        } else {
                            throw Error("Warning: possible CSRF breach");
                        }
                    })
                    .catch(err => {
                        console.log("oauthCode err", err);
                        toast.error(
                            `Could not log in with Clever. Please try again later.`
                        );
                        navigate(`/`);
                    });
            }
        },
        [oauthCode, navigate, stateParam, scope]
    );

    return (
        <div>
            <CssBaseline />
            <AppBarAndDrawer />
            <main>
                <div style={{ marginTop: "20em" }}>This may take a minute. Please wait.</div>
            </main>
        </div>
    );
};
