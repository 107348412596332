import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#E5E8E9",
    height: "100vh"
  },
  content: {
    backgroundColor: "#E5E8E9",
    flexGrow: 1
  },
  form: {
    display: "block",
    textAlign: "center",
    width: "100%"
  },
  formItems: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  label: {
    paddingRight: theme.spacing(14)
  },
  textField: {
    width: "32ch",
    fontSize: 20
  },
  selectField: {
    width: "32ch",
    fontSize: 20
  },
  input: {
    "&::placeholder": {
      color: "black",
      fontWeight: "bold"
    }
  },
  fieldLabel: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontSize: 20
  },
  fieldLabel2: {
    paddingTop: theme.spacing(2)
  },
  countriesRaised: {
    width: "17ch",
    display: "inline-block"
  },
  buttonBlock: { display: "block", textAlign: "center", width: "100%", marginTop: "1em" },
  buttons: {
    display: "inline-block",
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2)
  },
  colName: {
    fontWeight: "bold"
  },
  questionText: {
    fontSize: 20
  },
  checkBoxDiv: {
    // display: "inline-block",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  checkBox: {
    // textAlign: "left",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  screenerPaper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    display: "inline-block",
    overflow: "auto",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: 200,
    //TODO: change this when needing to display on mobile
    minWidth: "100vh"
  },
  mobileScreenerPaper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    display: "inline-block",
    overflow: "auto",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: 200,
  },
  literacyPaper: {
    // marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    display: "inline-block",
    overflow: "auto",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: 200,
    //TODO: change this when needing to display on mobile
    minWidth: "100vh"
  },
  screenerContainer: {
    backgroundColor: "#E5E8E9",
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    textAlign: "center"
  },
  footer: {
    backgroundColor: "#E5E8E9",
    padding: theme.spacing(2),
    bottom: 0,
    marginTop: "calc(5% + 5px)",
    width: "100%"
  },
  icon: {
    color: "#22223B"
  },
  questionIcon: {
    fontSize: "3em",
    color: "#1976D1"
  },
  "@keyframes glowing": {
    from: {
      boxShadow: "0 0 10px -10px #66ff66"
    },
    to: {
      boxShadow: "0 0 10px 10px #66ff66"
    }
  },
  glowing: {
    animationName: "$glowing",
    animationDuration: "1000ms",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: "ease-in-out"
  },
  loader: {
    textAlign: "center",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  confirmButton: {
    paddingTop: "2em"
  },
  wpmText: {
    textAlign: "left",
    fontSize: 19,
    lineHeight: 1.8,
    paddingLeft: "7em",
    paddingRight: "7em",
    paddingTop: "1.5em"
  },
  wpmTextAndButton: {
    textAlign: "center",
    fontSize: 19,
    lineHeight: 1.8,
    paddingLeft: "12em",
    paddingRight: "12em"
  },
  recorderIcon: {
    fontSize: "5em",
    color: "green"
  },
  loginPaper: {
    padding: 20,
    height: "auto",
    width: "45vh",
    margin: "12vh auto"
  },
  loginLogo: {
    width: "10em",
    height: "auto",
    margin: "1em"
  },
  loginHeaderTypography: {
    padding: "1em"
  },
  loginHelpLinks: {
    marginTop: "1em"
  },
  //start of AppBarAndDrawer styles
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  // This group of buttons will be aligned to the right
  rightToolbar: {
    marginLeft: "auto",
    marginRight: -12
  },
  drawer: {
    width: 0,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: 240,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    background: "linear-gradient(#2d3842, #1E272E)",
    // background: "#3D3E3E"
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  //end of AppBarAndDrawer styles
  fullscreen: {
    position: "fixed",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    background: "#1E272E",
    zIndex: 10,
  }
}));
