import { nativeLanguageId } from "../../objects/nativeLanguageId";
import { questionNextforOnSubmitNav } from "../questionNextforOnSubmitNav";
import { api } from "../axios";

const onSubmitForm = async (data, navigate, questionId, language, popup) => {
  const accessToken = window.localStorage.getItem("iat");
  //note abeeType is coming back as a string not a num
  const abeeType = window.localStorage.getItem("abeeType");
  const grade = window.localStorage.getItem("grade")

  let values = data.formData;


  if (typeof data.formData !== "string" && questionId === "59209") {
    values = data.formData.join(", ");
  }
  //this covers literacy ids
  else if (typeof data.formData !== "string" && questionId.length > 3) {
    values = JSON.stringify(data.formData);
  } else if (typeof data.formData !== "string" && questionId !== "8") {
    values = data.formData.join(", ");
  } else if (questionId === "8") {
    values = JSON.stringify(data.formData);
  }

  let next = questionNextforOnSubmitNav(questionId, grade);

  const body = {
    questionId: questionId,
    answerText: values,
    hlId: nativeLanguageId[language],
    engClicked: popup
  };

  // console.log("body sent to server -->", body);

  api
    .post(`/student-app/answer`, body, {
      headers: {
        // "Content-type": "application/json",
        authorization: `Bearer ${accessToken}`
      }
    })
    .then(() => {
      //modifying "next" to route accurately based on answer
      console.log("NEXT", next, questionId, grade)
      if (questionId === "2" && data.formData === "No") {
        next = "background/4";
      }
      else if (questionId === "9" && abeeType === "0") {
        next = "confirm";
      }
      //doing the actual routing
      if (next === "numeracy") {
        navigate(`/numeracy`);
      } else if (next === "confirm") {
        navigate(`/confirm`);
      } else {
        navigate(`/${next}`);
      }
    })
    .catch((error) => {
      //TODO: figure out how to handle this gracefully
      console.log(`Error: ${JSON.stringify(error)}`);
    });
};

export default onSubmitForm;
