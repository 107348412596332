import React, { useEffect, useState } from "react";
import { AllRoutes } from "./AllRoutes";
import { api } from "./helpers/axios";
import "./styles/App.css";
import jwtDecode from "jwt-decode";

export const App = () => {
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(window.localStorage.getItem("iat"));

  //gets token from storage
  useEffect(
    () => {
      const token = window.localStorage.getItem("iat")
      if (token) { setAccessToken(token) }
    },
    [setAccessToken]
  );

  useEffect(
    () => {
      if (!accessToken) {
        // console.log("not logged in");
        setLoading(false);
        return;
      } else {
        const { exp } = jwtDecode(accessToken);
        if (Date.now() >= exp * 1000) {
          //console.log("token expired?", Date.now(), exp * 1000);
          //refresh token is expired, get a new one
          api
            .post(`/refresh_token`, {}, {
              withCredentials: true,
              headers: {
                Accept: "application/json",
                // "Content-Type": "application/json"
              },
            })
            .then(async x => {
              // console.log("refresh response when expired --->", x)
              window.localStorage.setItem("iat", x.data.accessToken);
              setAccessToken(x.data.accessToken);
              setLoading(false);
              return;
            })
            .catch(err => {
              console.warn("Your refresh token is invalid. Try to relogin");
              console.error(err);
            });
        } else {
          // console.log("can keep using valid refresh token");
          setLoading(false);
          return true;
        }
      }
    },
    [accessToken]
  );


  //TODO: make a nicer loading page
  if (loading) {
    return <div>loading...</div>;
  }

  return <AllRoutes />;
};
