import { Checkbox } from "@material-ui/core";
import { MyAnswerAudioButton } from "./MyAnswerAudioButton";
import { MyCheckbox } from "./MyCheckbox";
import { useStyles } from "../../styles/styles";

export const ScreenerCheckbox = ({
  language,
  text,
  englishText,
  audio,
  altName,
  highlight
}) => {
  const classes = useStyles();

  let name = "formData";
  if (altName) {
    name = altName;
  }

  return (
    <div className={classes.checkBox}>
      {language === "Arabic" && (
        <MyAnswerAudioButton audio={audio} language={language} />
      )}
      <div style={{ backgroundColor: highlight }}>
        <MyCheckbox
          language={language}
          name={name}
          type="checkbox"
          value={englishText}
          as={Checkbox}
          label={text}
        />
      </div>
      {language !== "Arabic" && (
        <MyAnswerAudioButton audio={audio} language={language} />
      )}
    </div>
  );
};
