//only 4 through 21 in all languages
export const ageRangeNums = {
  Arabic: [
    // {
    //   name: "1",
    //   value: "1",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/1N-arabic.mp3"
    // },
    // {
    //   name: "2",
    //   value: "2",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/2N-arabic.mp3"
    // },
    // {
    //   name: "3",
    //   value: "3",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/3N-arabic.mp3"
    // },
    {
      name: "4",
      value: "4",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/4N-arabic.mp3"
    },
    {
      name: "5",
      value: "5",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/5N-arabic.mp3"
    },
    {
      name: "6",
      value: "6",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/6N-arabic.mp3"
    },
    {
      name: "7",
      value: "7",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/7N-arabic.mp3"
    },
    {
      name: "8",
      value: "8",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/8N-arabic.mp3"
    },
    {
      name: "9",
      value: "9",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/9N-arabic.mp3"
    },
    {
      name: "10",
      value: "10",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/10N-arabic.mp3"
    },
    {
      name: "11",
      value: "11",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/11N-arabic.mp3"
    },
    {
      name: "12",
      value: "12",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/12N-arabic.mp3"
    },
    {
      name: "13",
      value: "13",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/13N-arabic.mp3"
    },
    {
      name: "14",
      value: "14",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/14N-arabic.mp3"
    },
    {
      name: "15",
      value: "15",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/15N-arabic.mp3"
    },
    {
      name: "16",
      value: "16",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/16N-arabic.mp3"
    },
    {
      name: "17",
      value: "17",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/17N-arabic.mp3"
    },
    {
      name: "18",
      value: "18",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/18N-arabic.mp3"
    },
    {
      name: "19",
      value: "19",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/19N-arabic.mp3"
    },
    {
      name: "20",
      value: "20",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/20N-arabic.mp3"
    },
    {
      name: "21",
      value: "21",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/21N-arabic.mp3"
    }
  ],
  Bengali: [
    // {
    //   name: "1",
    //   value: "1",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/1N-bengali.mp3"
    // },
    // {
    //   name: "2",
    //   value: "2",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/2N-bengali.mp3"
    // },
    // {
    //   name: "3",
    //   value: "3",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/3N-bengali.mp3"
    // },
    {
      name: "4",
      value: "৪",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/4N-bengali.mp3"
    },
    {
      name: "5",
      value: "৫",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/5N-bengali.mp3"
    },
    {
      name: "6",
      value: "৬",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/6N-bengali.mp3"
    },
    {
      name: "7",
      value: "৭",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/7N-bengali.mp3"
    },
    {
      name: "8",
      value: "৮",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/8N-bengali.mp3"
    },
    {
      name: "9",
      value: "৯",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/9N-bengali.mp3"
    },
    {
      name: "10",
      value: "১০",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/10N-bengali.mp3"
    },
    {
      name: "11",
      value: "১১",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/11N-bengali.mp3"
    },
    {
      name: "12",
      value: "১২",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/12N-bengali.mp3"
    },
    {
      name: "13",
      value: "১৩",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/13N-bengali.mp3"
    },
    {
      name: "14",
      value: "১৪",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/14N-bengali.mp3"
    },
    {
      name: "15",
      value: "১৫",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/15N-bengali.mp3"
    },
    {
      name: "16",
      value: "১৬",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/16N-bengali.mp3"
    },
    {
      name: "17",
      value: "১৭",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/17N-bengali.mp3"
    },
    {
      name: "18",
      value: "১৮",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/18N-bengali.mp3"
    },
    {
      name: "19",
      value: "১৯",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/19N-bengali.mp3"
    },
    {
      name: "20",
      value: "২০",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/20N-bengali.mp3"
    },
    {
      name: "21",
      value: "২১",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/21N-bengali.mp3"
    }
  ],
  English: [
    // {
    //   name: "1",
    //   value: "1",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/english/1N-english.mp3"
    // },
    // {
    //   name: "2",
    //   value: "2",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/english/2N-english.mp3"
    // },
    // {
    //   name: "3",
    //   value: "3",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/english/3N-english.mp3"
    // },
    {
      name: "4",
      value: "4",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/4N-english.mp3"
    },
    {
      name: "5",
      value: "5",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/5N-english.mp3"
    },
    {
      name: "6",
      value: "6",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/6N-english.mp3"
    },
    {
      name: "7",
      value: "7",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/7N-english.mp3"
    },
    {
      name: "8",
      value: "8",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/8N-english.mp3"
    },
    {
      name: "9",
      value: "9",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/9N-english.mp3"
    },
    {
      name: "10",
      value: "10",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/10N-english.mp3"
    },
    {
      name: "11",
      value: "11",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/11N-english.mp3"
    },
    {
      name: "12",
      value: "12",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/12N-english.mp3"
    },
    {
      name: "13",
      value: "13",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/13N-english.mp3"
    },
    {
      name: "14",
      value: "14",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/14N-english.mp3"
    },
    {
      name: "15",
      value: "15",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/15N-english.mp3"
    },
    {
      name: "16",
      value: "16",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/16N-english.mp3"
    },
    {
      name: "17",
      value: "17",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/17N-english.mp3"
    },
    {
      name: "18",
      value: "18",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/18N-english.mp3"
    },
    {
      name: "19",
      value: "19",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/19N-english.mp3"
    },
    {
      name: "20",
      value: "20",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/20N-english.mp3"
    },
    {
      name: "21",
      value: "21",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/21N-english.mp3"
    }
  ],
  French: [
    // {
    //   name: "1",
    //   value: "1",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/french/1N-french.mp3"
    // },
    // {
    //   name: "2",
    //   value: "2",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/french/2N-french.mp3"
    // },
    // {
    //   name: "3",
    //   value: "3",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/french/3N-french.mp3"
    // },
    {
      name: "4",
      value: "4",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/4N-french.mp3"
    },
    {
      name: "5",
      value: "5",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/5N-french.mp3"
    },
    {
      name: "6",
      value: "6",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/6N-french.mp3"
    },
    {
      name: "7",
      value: "7",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/7N-french.mp3"
    },
    {
      name: "8",
      value: "8",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/8N-french.mp3"
    },
    {
      name: "9",
      value: "9",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/9N-french.mp3"
    },
    {
      name: "10",
      value: "10",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/10N-french.mp3"
    },
    {
      name: "11",
      value: "11",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/11N-french.mp3"
    },
    {
      name: "12",
      value: "12",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/12N-french.mp3"
    },
    {
      name: "13",
      value: "13",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/13N-french.mp3"
    },
    {
      name: "14",
      value: "14",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/14N-french.mp3"
    },
    {
      name: "15",
      value: "15",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/15N-french.mp3"
    },
    {
      name: "16",
      value: "16",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/16N-french.mp3"
    },
    {
      name: "17",
      value: "17",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/17N-french.mp3"
    },
    {
      name: "18",
      value: "18",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/18N-french.mp3"
    },
    {
      name: "19",
      value: "19",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/19N-french.mp3"
    },
    {
      name: "20",
      value: "20",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/20N-french.mp3"
    },
    {
      name: "21",
      value: "21",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/21N-french.mp3"
    }
  ],
  Portuguese: [
    // {
    //   name: "1",
    //   value: "1",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/1N-portuguese.mp3"
    // },
    // {
    //   name: "2",
    //   value: "2",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/2N-portuguese.mp3"
    // },
    // {
    //   name: "3",
    //   value: "3",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/3N-portuguese.mp3"
    // },
    {
      name: "4",
      value: "4",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/4N-portuguese.mp3"
    },
    {
      name: "5",
      value: "5",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/5N-portuguese.mp3"
    },
    {
      name: "6",
      value: "6",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/6N-portuguese.mp3"
    },
    {
      name: "7",
      value: "7",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/7N-portuguese.mp3"
    },
    {
      name: "8",
      value: "8",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/8N-portuguese.mp3"
    },
    {
      name: "9",
      value: "9",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/9N-portuguese.mp3"
    },
    {
      name: "10",
      value: "10",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/10N-portuguese.mp3"
    },
    {
      name: "11",
      value: "11",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/11N-portuguese.mp3"
    },
    {
      name: "12",
      value: "12",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/12N-portuguese.mp3"
    },
    {
      name: "13",
      value: "13",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/13N-portuguese.mp3"
    },
    {
      name: "14",
      value: "14",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/14N-portuguese.mp3"
    },
    {
      name: "15",
      value: "15",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/15N-portuguese.mp3"
    },
    {
      name: "16",
      value: "16",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/16N-portuguese.mp3"
    },
    {
      name: "17",
      value: "17",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/17N-portuguese.mp3"
    },
    {
      name: "18",
      value: "18",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/18N-portuguese.mp3"
    },
    {
      name: "19",
      value: "19",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/19N-portuguese.mp3"
    },
    {
      name: "20",
      value: "20",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/20N-portuguese.mp3"
    },
    {
      name: "21",
      value: "21",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/21N-portuguese.mp3"
    }
  ],
  Russian: [
    // {
    //   name: "1",
    //   value: "1",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/russian/1N-russian.mp3"
    // },
    // {
    //   name: "2",
    //   value: "2",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/russian/2N-russian.mp3"
    // },
    // {
    //   name: "3",
    //   value: "3",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/russian/3N-russian.mp3"
    // },
    {
      name: "4",
      value: "4",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/4N-russian.mp3"
    },
    {
      name: "5",
      value: "5",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/5N-russian.mp3"
    },
    {
      name: "6",
      value: "6",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/6N-russian.mp3"
    },
    {
      name: "7",
      value: "7",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/7N-russian.mp3"
    },
    {
      name: "8",
      value: "8",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/8N-russian.mp3"
    },
    {
      name: "9",
      value: "9",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/9N-russian.mp3"
    },
    {
      name: "10",
      value: "10",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/10N-russian.mp3"
    },
    {
      name: "11",
      value: "11",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/11N-russian.mp3"
    },
    {
      name: "12",
      value: "12",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/12N-russian.mp3"
    },
    {
      name: "13",
      value: "13",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/13N-russian.mp3"
    },
    {
      name: "14",
      value: "14",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/14N-russian.mp3"
    },
    {
      name: "15",
      value: "15",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/15N-russian.mp3"
    },
    {
      name: "16",
      value: "16",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/16N-russian.mp3"
    },
    {
      name: "17",
      value: "17",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/17N-russian.mp3"
    },
    {
      name: "18",
      value: "18",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/18N-russian.mp3"
    },
    {
      name: "19",
      value: "19",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/19N-russian.mp3"
    },
    {
      name: "20",
      value: "20",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/20N-russian.mp3"
    },
    {
      name: "21",
      value: "21",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/21N-russian.mp3"
    }
  ],
  Spanish: [
    // {
    //   name: "1",
    //   value: "1",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/1N-spanish.mp3"
    // },
    // {
    //   name: "2",
    //   value: "2",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/2N-spanish.mp3"
    // },
    // {
    //   name: "3",
    //   value: "3",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/3N-spanish.mp3"
    // },
    {
      name: "4",
      value: "4",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/4N-spanish.mp3"
    },
    {
      name: "5",
      value: "5",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/5N-spanish.mp3"
    },
    {
      name: "6",
      value: "6",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/6N-spanish.mp3"
    },
    {
      name: "7",
      value: "7",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/7N-spanish.mp3"
    },
    {
      name: "8",
      value: "8",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/8N-spanish.mp3"
    },
    {
      name: "9",
      value: "9",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/9N-spanish.mp3"
    },
    {
      name: "10",
      value: "10",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/10N-spanish.mp3"
    },
    {
      name: "11",
      value: "11",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/11N-spanish.mp3"
    },
    {
      name: "12",
      value: "12",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/12N-spanish.mp3"
    },
    {
      name: "13",
      value: "13",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/13N-spanish.mp3"
    },
    {
      name: "14",
      value: "14",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/14N-spanish.mp3"
    },
    {
      name: "15",
      value: "15",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/15N-spanish.mp3"
    },
    {
      name: "16",
      value: "16",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/16N-spanish.mp3"
    },
    {
      name: "17",
      value: "17",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/17N-spanish.mp3"
    },
    {
      name: "18",
      value: "18",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/18N-spanish.mp3"
    },
    {
      name: "19",
      value: "19",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/19N-spanish.mp3"
    },
    {
      name: "20",
      value: "20",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/20N-spanish.mp3"
    },
    {
      name: "21",
      value: "21",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/21N-spanish.mp3"
    }
  ],
  Vietnamese: [
    // {
    //   name: "1",
    //   value: "1",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/1N-vietnamese.mp3"
    // },
    // {
    //   name: "2",
    //   value: "2",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/2N-vietnamese.mp3"
    // },
    // {
    //   name: "3",
    //   value: "3",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/3N-vietnamese.mp3"
    // },
    {
      name: "4",
      value: "4",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/4N-vietnamese.mp3"
    },
    {
      name: "5",
      value: "5",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/5N-vietnamese.mp3"
    },
    {
      name: "6",
      value: "6",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/6N-vietnamese.mp3"
    },
    {
      name: "7",
      value: "7",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/7N-vietnamese.mp3"
    },
    {
      name: "8",
      value: "8",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/8N-vietnamese.mp3"
    },
    {
      name: "9",
      value: "9",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/9N-vietnamese.mp3"
    },
    {
      name: "10",
      value: "10",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/10N-vietnamese.mp3"
    },
    {
      name: "11",
      value: "11",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/11N-vietnamese.mp3"
    },
    {
      name: "12",
      value: "12",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/12N-vietnamese.mp3"
    },
    {
      name: "13",
      value: "13",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/13N-vietnamese.mp3"
    },
    {
      name: "14",
      value: "14",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/14N-vietnamese.mp3"
    },
    {
      name: "15",
      value: "15",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/15N-vietnamese.mp3"
    },
    {
      name: "16",
      value: "16",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/16N-vietnamese.mp3"
    },
    {
      name: "17",
      value: "17",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/17N-vietnamese.mp3"
    },
    {
      name: "18",
      value: "18",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/18N-vietnamese.mp3"
    },
    {
      name: "19",
      value: "19",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/19N-vietnamese.mp3"
    },
    {
      name: "20",
      value: "20",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/20N-vietnamese.mp3"
    },
    {
      name: "21",
      value: "21",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/21N-vietnamese.mp3"
    }
  ],
  Mam: [
    // {
    //   name: "1",
    //   value: "1",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/mam/1N-mam.mp3"
    // },
    // {
    //   name: "2",
    //   value: "2",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/mam/2N-mam.mp3"
    // },
    // {
    //   name: "3",
    //   value: "3",
    //   audio:
    //     "https://storage.googleapis.com/inlier-audio/aug-2021/mam/3N-mam.mp3"
    // },
    {
      name: "4",
      value: "4",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/4N-mam.mp3"
    },
    {
      name: "5",
      value: "5",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/5N-mam.mp3"
    },
    {
      name: "6",
      value: "6",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/6N-mam.mp3"
    },
    {
      name: "7",
      value: "7",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/7N-mam.mp3"
    },
    {
      name: "8",
      value: "8",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/8N-mam.mp3"
    },
    {
      name: "9",
      value: "9",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/9N-mam.mp3"
    },
    {
      name: "10",
      value: "10",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/10N-mam.mp3"
    },
    {
      name: "11",
      value: "11",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/11N-mam.mp3"
    },
    {
      name: "12",
      value: "12",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/12N-mam.mp3"
    },
    {
      name: "13",
      value: "13",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/13N-mam.mp3"
    },
    {
      name: "14",
      value: "14",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/14N-mam.mp3"
    },
    {
      name: "15",
      value: "15",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/15N-mam.mp3"
    },
    {
      name: "16",
      value: "16",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/16N-mam.mp3"
    },
    {
      name: "17",
      value: "17",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/17N-mam.mp3"
    },
    {
      name: "18",
      value: "18",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/18N-mam.mp3"
    },
    {
      name: "19",
      value: "19",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/19N-mam.mp3"
    },
    {
      name: "20",
      value: "20",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/20N-mam.mp3"
    },
    {
      name: "21",
      value: "21",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/21N-mam.mp3"
    }
  ]
};
