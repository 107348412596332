import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Copyright } from "../components/Copyright";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#E5E8E9",
    padding: theme.spacing(2),
    bottom: 0,
    color: "grey",
    width: "100%"
  }
}));

export const ScreenerFooter = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Copyright />
    </footer>
  );
};
