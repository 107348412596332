export const questionNextforOnSubmitNav = (questionId, grade) => {

    let obj = {
        59209: "background/104",
        104: "background/1",
        1: "background/2",
        2: "background/2B",
        "2B": "background/3",
        3: "background/4",
        4: "background/5",
        5: "background/6",
        6: "background/7",
        7: "background/8",
        8: "background/9",
    }

    if (grade === "5") {
        obj[9] = "literacy/lit5-78363-1"
        obj["lit5-78363-1"] = "literacy/lit5-78363-2"
        obj["lit5-78363-2"] = "literacy/lit5-78363-3"
        obj["lit5-78363-3"] = "literacy/lit5-78363-4"
        obj["lit5-78363-4"] = "numeracy/num5-21389"
        obj["num5-21389"] = "numeracy/num5-00740"
        obj["num5-00740"] = "numeracy/num5-86340"
        obj["num5-86340"] = "numeracy/num5-15811"
        obj["num5-15811"] = "numeracy/num5-25484"
        obj["num5-25484"] = "numeracy/num5-80549"
        obj["num5-80549"] = "numeracy/num5-52838"
        obj["num5-52838"] = "numeracy/num5-40806"
        obj["num5-40806"] = "confirm"
        // console.log("OBJ", obj)
        return obj[questionId]
    } else if (grade === "6") {
        obj[9] = "literacy/lit6-77236-1"
        obj["lit6-77236-1"] = "literacy/lit6-77236-2"
        obj["lit6-77236-2"] = "literacy/lit6-77236-3"
        obj["lit6-77236-3"] = "literacy/lit6-77236-4"
        obj["lit6-77236-4"] = "numeracy/num6-45703"
        obj["num6-45703"] = "numeracy/num6-49147"
        obj["num6-49147"] = "numeracy/num6-83265"
        obj["num6-83265"] = "numeracy/num6-54571"
        obj["num6-54571"] = "numeracy/num6-43012"
        obj["num6-43012"] = "numeracy/num6-19231"
        obj["num6-19231"] = "numeracy/num6-46851"
        obj["num6-46851"] = "numeracy/num6-76575"
        obj["num6-76575"] = "confirm"
        // console.log("OBJ", obj)
        return obj[questionId]
    } else if (grade === "7") {
        obj[9] = "literacy/lit7-12998-1"
        obj["lit7-12998-1"] = "literacy/lit7-12998-2"
        obj["lit7-12998-2"] = "literacy/lit7-12998-3"
        obj["lit7-12998-3"] = "literacy/lit7-12998-4"
        obj["lit7-12998-4"] = "numeracy/num7-45704"
        obj["num7-45704"] = "numeracy/num7-49148"
        obj["num7-49148"] = "numeracy/num7-83267"
        obj["num7-83267"] = "numeracy/num7-54572"
        obj["num7-54572"] = "numeracy/num7-43013"
        obj["num7-43013"] = "numeracy/num7-19232"
        obj["num7-19232"] = "numeracy/num7-46852"
        obj["num7-46852"] = "numeracy/num7-76576"
        obj["num7-76576"] = "confirm"
        // console.log("OBJ", obj)
        return obj[questionId]
    }
    else if (grade === "8") {
        obj[9] = "literacy/lit8-10048-1"
        obj["lit8-10048-1"] = "literacy/lit8-10048-2"
        obj["lit8-10048-2"] = "literacy/lit8-10048-3"
        obj["lit8-10048-3"] = "literacy/lit8-10048-4"
        obj["lit8-10048-4"] = "numeracy/num8-45705"
        obj["num8-45705"] = "numeracy/num8-49149"
        obj["num8-49149"] = "numeracy/num8-83268"
        obj["num8-83268"] = "numeracy/num8-54573"
        obj["num8-54573"] = "numeracy/num8-43014"
        obj["num8-43014"] = "numeracy/num8-19233"
        obj["num8-19233"] = "numeracy/num8-46853"
        obj["num8-46853"] = "numeracy/num8-76577"
        obj["num8-76577"] = "confirm"
        // console.log("OBJ", obj)
        return obj[questionId]
    }
};

