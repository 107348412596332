//there's another abeeQuestionOrder called skipOrder in the Login.jsx page, all changes to this one should be made there too
export const abeeQuestionOrder = (abeeType, grade, skip) => {
    if (abeeType === "1" && grade === "5" && !skip) {
        return [
            "59209",
            "104",
            "1",
            "2",
            "2B",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "lit5-78363-1",
            "lit5-78363-2",
            "lit5-78363-3",
            "lit5-78363-4",
            "num5-21389",
            "num5-00740",
            "num5-86340",
            "num5-15811",
            "num5-25484",
            "num5-80549",
            "num5-52838",
            "num5-40806"];
    } else if (abeeType === "1" && grade === "5" && skip) {
        return [
            "59209",
            "104",
            "1",
            "2",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "lit5-78363-1",
            "lit5-78363-2",
            "lit5-78363-3",
            "lit5-78363-4",
            "num5-21389",
            "num5-00740",
            "num5-86340",
            "num5-15811",
            "num5-25484",
            "num5-80549",
            "num5-52838",
            "num5-40806"
        ];
    } else if (abeeType === "1" && grade === "6" && !skip) {
        return [
            "59209",
            "104",
            "1",
            "2",
            "2B",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "lit6-77236-1",
            "lit6-77236-2",
            "lit6-77236-3",
            "lit6-77236-4",
            "num6-45703",
            "num6-49147",
            "num6-83265",
            "num6-54571",
            "num6-43012",
            "num6-19231",
            "num6-46851",
            "num6-76575"];
    } else if (abeeType === "1" && grade === "6" && skip) {
        return [
            "59209",
            "104",
            "1",
            "2",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "lit6-77236-1",
            "lit6-77236-2",
            "lit6-77236-3",
            "lit6-77236-4",
            "num6-45703",
            "num6-49147",
            "num6-83265",
            "num6-54571",
            "num6-43012",
            "num6-19231",
            "num6-46851",
            "num6-76575"
        ];
    } else if (abeeType === "1" && grade === "7" && !skip) {
        return [
            "59209",
            "104",
            "1",
            "2",
            "2B",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "lit7-12998-1",
            "lit7-12998-2",
            "lit7-12998-3",
            "lit7-12998-4",
            "num7-45704",
            "num7-49148",
            "num7-83267",
            "num7-54572",
            "num7-43013",
            "num7-19232",
            "num7-46852",
            "num7-76576"];
    } else if (abeeType === "1" && grade === "7" && skip) {
        return [
            "59209",
            "104",
            "1",
            "2",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "lit7-12998-1",
            "lit7-12998-2",
            "lit7-12998-3",
            "lit7-12998-4",
            "num7-45704",
            "num7-49148",
            "num7-83267",
            "num7-54572",
            "num7-43013",
            "num7-19232",
            "num7-46852",
            "num7-76576"
        ];
    } else if (abeeType === "1" && grade === "8" && !skip) {
        return [
            "59209",
            "104",
            "1",
            "2",
            "2B",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "lit8-10048-1",
            "lit8-10048-2",
            "lit8-10048-3",
            "lit8-10048-4",
            "num8-45705",
            "num8-49149",
            "num8-83268",
            "num8-54573",
            "num8-43014",
            "num8-19233",
            "num8-46853",
            "num8-76577"];
    } else if (abeeType === "1" && grade === "8" && skip) {
        return [
            "59209",
            "104",
            "1",
            "2",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "lit8-10048-1",
            "lit8-10048-2",
            "lit8-10048-3",
            "lit8-10048-4",
            "num8-45705",
            "num8-49149",
            "num8-83268",
            "num8-54573",
            "num8-43014",
            "num8-19233",
            "num8-46853",
            "num8-76577"
        ];
    } else if (abeeType === "0" && !skip) {
        return ["59209", "104", "1", "2", "2B", "3", "4", "5", "6", "7", "8", "9"];
    } else if (abeeType === "0" && skip) {
        return ["59209", "104", "1", "2", "4", "5", "6", "7", "8", "9"];
    }
}

