//only 1 through 13 in all languages
export const lastGradeCompleted = {
  Arabic: [
    {
      name: "1",
      value: "1",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/1N-arabic.mp3"
    },
    {
      name: "2",
      value: "2",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/2N-arabic.mp3"
    },
    {
      name: "3",
      value: "3",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/3N-arabic.mp3"
    },
    {
      name: "4",
      value: "4",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/4N-arabic.mp3"
    },
    {
      name: "5",
      value: "5",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/5N-arabic.mp3"
    },
    {
      name: "6",
      value: "6",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/6N-arabic.mp3"
    },
    {
      name: "7",
      value: "7",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/7N-arabic.mp3"
    },
    {
      name: "8",
      value: "8",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/8N-arabic.mp3"
    },
    {
      name: "9",
      value: "9",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/9N-arabic.mp3"
    },
    {
      name: "10",
      value: "10",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/10N-arabic.mp3"
    },
    {
      name: "11",
      value: "11",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/11N-arabic.mp3"
    },
    {
      name: "12",
      value: "12",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/12N-arabic.mp3"
    }
  ],
  Bengali: [
    {
      name: "1",
      value: "১",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/1N-bengali.mp3"
    },
    {
      name: "2",
      value: "২",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/2N-bengali.mp3"
    },
    {
      name: "3",
      value: "৩",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/3N-bengali.mp3"
    },
    {
      name: "4",
      value: "৪",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/4N-bengali.mp3"
    },
    {
      name: "5",
      value: "৫",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/5N-bengali.mp3"
    },
    {
      name: "6",
      value: "৬",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/6N-bengali.mp3"
    },
    {
      name: "7",
      value: "৭",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/7N-bengali.mp3"
    },
    {
      name: "8",
      value: "৮",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/8N-bengali.mp3"
    },
    {
      name: "9",
      value: "৯",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/9N-bengali.mp3"
    },
    {
      name: "10",
      value: "১০",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/10N-bengali.mp3"
    },
    {
      name: "11",
      value: "১১",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/11N-bengali.mp3"
    },
    {
      name: "12",
      value: "১২",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/12N-bengali.mp3"
    }
  ],
  English: [
    {
      name: "1",
      value: "1",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/1N-english.mp3"
    },
    {
      name: "2",
      value: "2",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/2N-english.mp3"
    },
    {
      name: "3",
      value: "3",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/3N-english.mp3"
    },
    {
      name: "4",
      value: "4",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/4N-english.mp3"
    },
    {
      name: "5",
      value: "5",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/5N-english.mp3"
    },
    {
      name: "6",
      value: "6",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/6N-english.mp3"
    },
    {
      name: "7",
      value: "7",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/7N-english.mp3"
    },
    {
      name: "8",
      value: "8",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/8N-english.mp3"
    },
    {
      name: "9",
      value: "9",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/9N-english.mp3"
    },
    {
      name: "10",
      value: "10",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/10N-english.mp3"
    },
    {
      name: "11",
      value: "11",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/11N-english.mp3"
    },
    {
      name: "12",
      value: "12",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/english/12N-english.mp3"
    }
  ],
  French: [
    {
      name: "1",
      value: "1",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/1N-french.mp3"
    },
    {
      name: "2",
      value: "2",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/2N-french.mp3"
    },
    {
      name: "3",
      value: "3",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/3N-french.mp3"
    },
    {
      name: "4",
      value: "4",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/4N-french.mp3"
    },
    {
      name: "5",
      value: "5",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/5N-french.mp3"
    },
    {
      name: "6",
      value: "6",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/6N-french.mp3"
    },
    {
      name: "7",
      value: "7",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/7N-french.mp3"
    },
    {
      name: "8",
      value: "8",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/8N-french.mp3"
    },
    {
      name: "9",
      value: "9",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/9N-french.mp3"
    },
    {
      name: "10",
      value: "10",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/10N-french.mp3"
    },
    {
      name: "11",
      value: "11",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/11N-french.mp3"
    },
    {
      name: "12",
      value: "12",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/french/12N-french.mp3"
    }
  ],
  Portuguese: [
    {
      name: "1",
      value: "1",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/1N-portuguese.mp3"
    },
    {
      name: "2",
      value: "2",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/2N-portuguese.mp3"
    },
    {
      name: "3",
      value: "3",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/3N-portuguese.mp3"
    },
    {
      name: "4",
      value: "4",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/4N-portuguese.mp3"
    },
    {
      name: "5",
      value: "5",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/5N-portuguese.mp3"
    },
    {
      name: "6",
      value: "6",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/6N-portuguese.mp3"
    },
    {
      name: "7",
      value: "7",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/7N-portuguese.mp3"
    },
    {
      name: "8",
      value: "8",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/8N-portuguese.mp3"
    },
    {
      name: "9",
      value: "9",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/9N-portuguese.mp3"
    },
    {
      name: "10",
      value: "10",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/10N-portuguese.mp3"
    },
    {
      name: "11",
      value: "11",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/11N-portuguese.mp3"
    },
    {
      name: "12",
      value: "12",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/12N-portuguese.mp3"
    }
  ],
  Russian: [
    {
      name: "1",
      value: "1",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/1N-russian.mp3"
    },
    {
      name: "2",
      value: "2",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/2N-russian.mp3"
    },
    {
      name: "3",
      value: "3",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/3N-russian.mp3"
    },
    {
      name: "4",
      value: "4",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/4N-russian.mp3"
    },
    {
      name: "5",
      value: "5",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/5N-russian.mp3"
    },
    {
      name: "6",
      value: "6",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/6N-russian.mp3"
    },
    {
      name: "7",
      value: "7",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/7N-russian.mp3"
    },
    {
      name: "8",
      value: "8",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/8N-russian.mp3"
    },
    {
      name: "9",
      value: "9",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/9N-russian.mp3"
    },
    {
      name: "10",
      value: "10",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/10N-russian.mp3"
    },
    {
      name: "11",
      value: "11",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/11N-russian.mp3"
    },
    {
      name: "12",
      value: "12",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/russian/12N-russian.mp3"
    }
  ],
  Spanish: [
    {
      name: "1",
      value: "1",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/1N-spanish.mp3"
    },
    {
      name: "2",
      value: "2",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/2N-spanish.mp3"
    },
    {
      name: "3",
      value: "3",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/3N-spanish.mp3"
    },
    {
      name: "4",
      value: "4",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/4N-spanish.mp3"
    },
    {
      name: "5",
      value: "5",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/5N-spanish.mp3"
    },
    {
      name: "6",
      value: "6",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/6N-spanish.mp3"
    },
    {
      name: "7",
      value: "7",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/7N-spanish.mp3"
    },
    {
      name: "8",
      value: "8",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/8N-spanish.mp3"
    },
    {
      name: "9",
      value: "9",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/9N-spanish.mp3"
    },
    {
      name: "10",
      value: "10",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/10N-spanish.mp3"
    },
    {
      name: "11",
      value: "11",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/11N-spanish.mp3"
    },
    {
      name: "12",
      value: "12",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/12N-spanish.mp3"
    }
  ],
  Vietnamese: [
    {
      name: "1",
      value: "1",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/1N-vietnamese.mp3"
    },
    {
      name: "2",
      value: "2",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/2N-vietnamese.mp3"
    },
    {
      name: "3",
      value: "3",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/3N-vietnamese.mp3"
    },
    {
      name: "4",
      value: "4",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/4N-vietnamese.mp3"
    },
    {
      name: "5",
      value: "5",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/5N-vietnamese.mp3"
    },
    {
      name: "6",
      value: "6",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/6N-vietnamese.mp3"
    },
    {
      name: "7",
      value: "7",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/7N-vietnamese.mp3"
    },
    {
      name: "8",
      value: "8",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/8N-vietnamese.mp3"
    },
    {
      name: "9",
      value: "9",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/9N-vietnamese.mp3"
    },
    {
      name: "10",
      value: "10",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/10N-vietnamese.mp3"
    },
    {
      name: "11",
      value: "11",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/11N-vietnamese.mp3"
    },
    {
      name: "12",
      value: "12",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/12N-vietnamese.mp3"
    }
  ],
  Mam: [
    {
      name: "1",
      value: "1",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/1N-mam.mp3"
    },
    {
      name: "2",
      value: "2",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/2N-mam.mp3"
    },
    {
      name: "3",
      value: "3",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/3N-mam.mp3"
    },
    {
      name: "4",
      value: "4",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/4N-mam.mp3"
    },
    {
      name: "5",
      value: "5",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/5N-mam.mp3"
    },
    {
      name: "6",
      value: "6",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/6N-mam.mp3"
    },
    {
      name: "7",
      value: "7",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/7N-mam.mp3"
    },
    {
      name: "8",
      value: "8",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/8N-mam.mp3"
    },
    {
      name: "9",
      value: "9",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/9N-mam.mp3"
    },
    {
      name: "10",
      value: "10",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/10N-mam.mp3"
    },
    {
      name: "11",
      value: "11",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/11N-mam.mp3"
    },
    {
      name: "12",
      value: "12",
      audio:
        "https://storage.googleapis.com/inlier-audio/aug-2021/mam/12N-mam.mp3"
    }
  ]
};
