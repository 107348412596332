import React from "react";
import { useField } from "formik";
import { Radio, FormControlLabel } from "@material-ui/core";

export const MyRadio = ({ label, language, fontSize, ...props }) => {
  let labelPlacement = "";
  let sizeOfFont = 18;

  if (language === "Arabic") {
    labelPlacement = "start";
  }
  const [field] = useField(props);

  if (fontSize) {
    sizeOfFont = fontSize;
  }

  return (
    <FormControlLabel
      {...field}
      control={<Radio />}
      label={<span style={{ fontSize: sizeOfFont }}>{label}</span>}
      labelPlacement={labelPlacement}
    />
  );
};
