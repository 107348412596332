import React, { Fragment, useState } from "react";
import { useStyles } from "../../styles/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const NumSelect = ({ language, numbers, formValues, setFormValues }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleSelectorChange = (event) => {
    setFormValues({ formData: event.target.value });
    let sub;
    if (numbers[language][0].value === "4") {
      sub = 4;
    } else {
      sub = 1;
    }
    const ind = parseInt(event.target.value) - sub;
    let sound = new Audio(numbers[language][ind]["audio"]);
    sound.play();
  };

  const handleSelectorClose = () => {
    setOpen(false);
  };

  const handleSelectorOpen = () => {
    setOpen(true);
  };

  return (
    <Fragment>
      <div style={{ paddingTop: "3ch", paddingBottom: "3ch" }}>
        <FormControl className={classes.formControl}>
          <Select
            style={{ width: "15ch" }}
            open={open}
            onClose={handleSelectorClose}
            onOpen={handleSelectorOpen}
            value={formValues.formData}
            onChange={handleSelectorChange}
          >
            {numbers[language].map((number) => (
              <MenuItem value={number.value} key={number.name}>
                {number.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </Fragment>
  );
};

export default NumSelect;
