export const answeredRequired = {
  Arabic: "الإجابة المطلوبة",
  Bengali: "প্রয়োজনীয় উত্তর",
  English: "answer required",
  French: "réponse requise",
  Portuguese: "resposta necessária",
  Russian: "требуется ответ",
  Spanish: "respuesta requerida",
  Vietnamese: "câu trả lời yêu cầu",
  Mam: "answer required"
};

export const answeredRequiredAudio = {
  Arabic:
    "https://storage.googleapis.com/inlier-audio/aug-2021/arabic/ar-arabic.mp3",
  Bengali:
    "https://storage.googleapis.com/inlier-audio/aug-2021/bengali/ar-bengali.mp3",
  English:
    "https://storage.googleapis.com/inlier-audio/aug-2021/english/ar-english.mp3",
  French:
    "https://storage.googleapis.com/inlier-audio/aug-2021/french/ar-french.mp3",
  Portuguese:
    "https://storage.googleapis.com/inlier-audio/aug-2021/portuguese/ar-portuguese.mp3",
  Russian:
    "https://storage.googleapis.com/inlier-audio/aug-2021/russian/ar-russian.mp3",
  Spanish:
    "https://storage.googleapis.com/inlier-audio/aug-2021/spanish/ar-spanish.mp3",
  Vietnamese:
    "https://storage.googleapis.com/inlier-audio/aug-2021/vietnamese/ar-vietnamese.mp3",
  Mam: "https://storage.googleapis.com/inlier-audio/aug-2021/english/ar-english.mp3"
};
