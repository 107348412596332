import city1 from "../../../styles/images/cities/city1.jpg";
import city2 from "../../../styles/images/cities/city2.jpg";
import city3 from "../../../styles/images/cities/city3.jpg";
import city4 from "../../../styles/images/cities/city4.jpg";

export const cityImageList = [
  {
    img: city1,
    title: "city one"
  },
  {
    img: city2,
    title: "city two"
  },
  {
    img: city3,
    title: "city three"
  },
  {
    img: city4,
    title: "city four"
  }
];
