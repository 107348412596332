import { useStyles } from "../../styles/styles";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

export const ButtonBlock = ({ language, first }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { glowing } = useStyles();

  return (
    <div className={classes.buttonBlock}>
      {language !== "Arabic" && !first && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          >
            {language !== "Arabic" && <NavigateBeforeIcon></NavigateBeforeIcon>}
          </Button>
        </div>
      )}
      <div className={classes.buttons}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={`${glowing}`}
        >
          {language === "Arabic" && <NavigateBeforeIcon></NavigateBeforeIcon>}
          {language !== "Arabic" && <NavigateNextIcon></NavigateNextIcon>}
        </Button>
      </div>
      {language === "Arabic" && !first && (
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
          >
            {language === "Arabic" && <NavigateNextIcon></NavigateNextIcon>}
          </Button>
        </div>
      )}
    </div>
  );
};
