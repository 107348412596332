import React, { useState } from "react";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemText
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../styles/styles";
import { api } from "../../helpers/axios";

export const NavLoginButton = () => {

  const accessToken = window.localStorage.getItem("iat");
  const username = window.localStorage.getItem("iun");
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const { glowing } = useStyles();

  const login = async () => {
    navigate("/login")
  };

  const logout = async () => {
    api
      .post(
        `/auth/logout`,
        {},
        {
          withCredentials: true,
          headers: {
            // "Content-Type": "application/json",
            authorization: `Bearer ${accessToken}`
          }
        }
      )
      .then(async response => {
        if (response && response.data) {
          // console.log("reponse data", response.data)
          window.localStorage.clear();
          return navigate("/");
        }
      })
      .catch(err => {
        console.error(err);
      });
    navigate("/")
  }


  const menuPosition = {
    vertical: "top",
    horizontal: "right"
  };

  const handleMenuOpen = (event) =>
    setMenuAnchorEl({ menuAnchorEl: event.currentTarget });

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <div>
      {accessToken && (
        <div>
          <IconButton onClick={handleMenuOpen} style={{ color: "#e6ecf2" }}>
            <AccountCircle />
          </IconButton>
          <Menu
            anchorEl={menuAnchorEl}
            anchorOrigin={menuPosition}
            getContentAnchorEl={null}
            transformOrigin={menuPosition}
            open={!!menuAnchorEl}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={logout}>
              <ListItemText
                primary="Logout"
                secondary={username}
              />
            </MenuItem>
          </Menu>
        </div>
      )}
      {!accessToken && (
        <Button
          variant="contained"
          style={{ color: "#C9E165" }}
          onClick={login}
          className={`${glowing}`}
          size="large"
        >
          Login
        </Button>
      )}
    </div>
  );
};

