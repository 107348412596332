export const numeracyQuestionsV1 = {
  "num5-21389": {
    id: "num5-21389",
    equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;1&space;&plus;&space;(2&space;%5Ctimes&space;3)&space;=",
    body: null,
    audio: null,
    correctId: "num5-21389c",
    options: [
      {
        id: "num5-21389a",
        equationURL: null,
        body: "2",
        audio: null
      },
      {
        id: "num5-21389b",
        equationURL: null,
        body: "6",
        audio: null
      },
      {
        id: "num5-21389c",
        equationURL: null,
        body: "7",
        audio: null
      },
      {
        id: "num5-21389d",
        equationURL: null,
        body: "9",
        audio: null
      }
    ],
  },
  "num5-00740": {
    id: "num5-00740",
    equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;2%5Ctimes3&plus;4%5Cdiv&space;2=",
    body: null,
    audio: null,
    correctId: "num5-00740c",
    options: [
      {
        id: "num5-00740a",
        equationURL: null,
        body: "5",
        audio: null
      },
      {
        id: "num5-00740b",
        equationURL: null,
        body: "7",
        audio: null
      },
      {
        id: "num5-00740c",
        equationURL: null,
        body: "8",
        audio: null
      },
      {
        id: "num5-00740d",
        equationURL: null,
        body: "11",
        audio: null
      }
    ],
  },
  "num5-86340": {
    id: "num5-86340",
    equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;1&plus;(5-1)%5Ctimes&space;3=",
    body: null,
    audio: null,
    correctId: "num5-86340c",
    options: [
      {
        id: "num5-86340a",
        equationURL: null,
        body: "3",
        audio: null
      },
      {
        id: "num5-86340b",
        equationURL: null,
        body: "5",
        audio: null
      },
      {
        id: "num5-86340c",
        equationURL: null,
        body: "13",
        audio: null
      },
      {
        id: "num5-86340d",
        equationURL: null,
        body: "15",
        audio: null
      }
    ],
  },
  "num5-15811": {
    id: "num5-15811",
    equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;0.4%5Ctimes3.0=",
    body: null,
    audio: null,
    correctId: "num5-15811b",
    options: [
      {
        id: "num5-15811a",
        equationURL: null,
        body: "0.12",
        audio: null
      },
      {
        id: "num5-15811b",
        equationURL: null,
        body: "1.2",
        audio: null
      },
      {
        id: "num5-15811c",
        equationURL: null,
        body: "12.",
        audio: null
      },
      {
        id: "num5-15811d",
        equationURL: null,
        body: "12",
        audio: null
      }
    ],
  },
  "num5-25484": {
    id: "num5-25484",
    equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;%5Cfrac%7B12%7D%7B1%7D&space;%5Ctimes&space;%5Cfrac%7B4%7D%7B3%7D=",
    body: null,
    audio: null,
    correctId: "num5-25484d",
    options: [
      {
        id: "num5-25484a",
        equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;%5Cfrac%7B9%7D%7B4%7D",
        body: null,
        audio: null
      },
      {
        id: "num5-25484b",
        equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;%5Cfrac%7B16%7D%7B3%7D",
        body: null,
        audio: null
      },
      {
        id: "num5-25484c",
        equationURL: null,
        body: "9",
        audio: null
      },
      {
        id: "num5-25484d",
        equationURL: null,
        body: "16",
        audio: null
      }
    ],
  },
  "num5-80549": {
    id: "num5-80549",
    equationURL: null,
    body: "Si empiezas con 123 y lo multiplicas por 13, ¿qué sucede con el número original?",
    audio: "https://storage.googleapis.com/inlier-audio/numeracy/spanish/num5-80549-spanish.mp3",
    correctId: "num5-80549a",
    options: [
      {
        id: "num5-80549a",
        equationURL: null,
        body: "Se hace mas pequeño",
        audio: "https://storage.googleapis.com/inlier-audio/numeracy/spanish/num5-80549a-spanish.mp3"
      },
      {
        id: "num5-80549b",
        equationURL: null,
        body: "Se hace mas grande",
        audio: "https://storage.googleapis.com/inlier-audio/numeracy/spanish/num5-80549b-spanish.mp3"
      },
      {
        id: "num5-80549c",
        equationURL: null,
        body: "Se mantiene igual",
        audio: "https://storage.googleapis.com/inlier-audio/numeracy/spanish/num5-80549c-spanish.mp3"
      },
      {
        id: "num5-80549d",
        equationURL: null,
        body: "Ninguna opción aplica",
        audio: "https://storage.googleapis.com/inlier-audio/numeracy/spanish/num5-80549d-spanish.mp3"
      }
    ],
  },
  "num5-52838": {
    id: "num5-52838",
    equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;%5Cfrac%7B1%7D%7B2%7D&plus;%5Cfrac%7B2%7D%7B5%7D=",
    body: null,
    audio: null,
    correctId: "num5-52838c",
    options: [
      {
        id: "num5-52838a",
        equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;%5Cfrac%7B3%7D%7B7%7D",
        body: null,
        audio: null
      },
      {
        id: "num5-52838b",
        equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;%5Cfrac%7B3%7D%7B5%7D",
        body: null,
        audio: null
      },
      {
        id: "num5-52838c",
        equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;%5Cfrac%7B9%7D%7B10%7D",
        body: null,
        audio: null
      },
      {
        id: "num5-52838d",
        equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;%5Cfrac%7B3%7D%7B2%7D",
        body: null,
        audio: null
      }
    ],
  },
  "num5-40806": {
    id: "num5-40806",
    equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;2-%5Cfrac%7B2%7D%7B5%7D=",
    body: null,
    audio: null,
    correctId: "num5-40806d",
    options: [
      {
        id: "num5-40806a",
        equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;%5Cfrac%7B1%7D%7B5%7D",
        body: null,
        audio: null
      },
      {
        id: "num5-40806b",
        equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;%5Cfrac%7B3%7D%7B5%7D",
        body: null,
        audio: null
      },
      {
        id: "num5-40806c",
        equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;1&space;%5Cfrac%7B1%7D%7B2%7D",
        body: null,
        audio: null
      },
      {
        id: "num5-40806d",
        equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;1&space;%5Cfrac%7B3%7D%7B5%7D",
        body: null,
        audio: null
      }
    ],
  },
  "num6-45703": {
    id: "num6-45703",
    equationURL: null,
    body: `¿Cuál de los siguientes tiene el mayor valor (cuál es el más grande)?`,
    audio: `https://storage.googleapis.com/inlier-audio/numeracy/spanish/num6-45703-spanish.mp3`,
    correctId: "num6-45703b",
    options: [
      {
        id: "num6-45703a",
        equationURL: `https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;2%5E%7B3%7D`,
        body: null,
        audio: null
      },
      {
        id: "num6-45703b",
        equationURL: `https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;3%5E%7B2%7D`,
        body: null,
        audio: null
      },
      {
        id: "num6-45703c",
        equationURL: `https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;2%5E%7B2%7D`,
        body: null,
        audio: null
      },
      {
        id: "num6-45703d",
        equationURL: `https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;5%5E%7B1%7D`,
        body: null,
        audio: null
      }
    ],
  },
  "num6-49147": {
    id: "num6-49147",
    equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;5&space;&plus;&space;(-7)&space;=",
    body: null,
    audio: null,
    correctId: "num6-49147a",
    options: [
      {
        id: "num6-49147a",
        equationURL: null,
        body: "-2",
        audio: null
      },
      {
        id: "num6-49147b",
        equationURL: null,
        body: "2",
        audio: null
      },
      {
        id: "num6-49147c",
        equationURL: null,
        body: "12",
        audio: null
      },
      {
        id: "num6-49147d",
        equationURL: null,
        body: "-12",
        audio: null
      }
    ],
  },
  "num6-83265": {
    id: "num6-83265",
    equationURL: null,
    body: `¿Cuál de los siguientes NO es igual al 70%?`,
    audio: `https://storage.googleapis.com/inlier-audio/numeracy/spanish/num6-83265-spanish.mp3`,
    correctId: "num6-83265b",
    options: [
      {
        id: "num6-83265a",
        equationURL: null,
        body: "0.7",
        audio: null
      },
      {
        id: "num6-83265b",
        equationURL: null,
        body: "0.07",
        audio: null
      },
      {
        id: "num6-83265c",
        equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;%5Cfrac%7B7%7D%7B10%7D",
        body: null,
        audio: null
      },
      {
        id: "num6-83265d",
        equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;%5Cfrac%7B70%7D%7B100%7D",
        body: null,
        audio: null
      }
    ],
  },
  "num6-54571": {
    id: "num6-54571",
    equationURL: `https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;1&plus;3%5Ctimes(8-6%5Cdiv&space;2)=`,
    body: null,
    audio: null,
    correctId: "num6-54571c",
    options: [
      {
        id: "num6-54571a",
        equationURL: null,
        body: "4",
        audio: null
      },
      {
        id: "num6-54571b",
        equationURL: null,
        body: "13",
        audio: null
      },
      {
        id: "num6-54571c",
        equationURL: null,
        body: "16",
        audio: null
      },
      {
        id: "num6-54571d",
        equationURL: null,
        body: "8",
        audio: null
      }
    ],
  },
  "num6-43012": {
    id: "num6-43012",
    equationURL: `https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;2%5E%7B3%7D-3%5E%7B1%7D=`,
    body: null,
    audio: null,
    correctId: "num6-43012d",
    options: [
      {
        id: "num6-43012a",
        equationURL: null,
        body: "1",
        audio: null
      },
      {
        id: "num6-43012b",
        equationURL: null,
        body: "3",
        audio: null
      },
      {
        id: "num6-43012c",
        equationURL: null,
        body: "6",
        audio: null
      },
      {
        id: "num6-43012d",
        equationURL: null,
        body: "5",
        audio: null
      }
    ],
  },
  "num6-19231": {
    id: "num6-19231",
    equationURL: null,
    body: `¿Qué expresión es igual a: "El número 6 se incrementa por 2 y el resultado se divide por 2"?`,
    audio: "https://storage.googleapis.com/inlier-audio/numeracy/spanish/num6-19231-spanish.mp3",
    correctId: "num6-19231a",
    options: [
      {
        id: "num6-19231a",
        equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;(6&plus;2)%5Cdiv&space;2",
        body: null,
        audio: null
      },
      {
        id: "num6-19231b",
        equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;6&plus;2%5Cdiv&space;2",
        body: null,
        audio: null
      },
      {
        id: "num6-19231c",
        equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;6&plus;(2%5Cdiv&space;2)",
        body: null,
        audio: null
      },
      {
        id: "num6-19231d",
        equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;6%5Cdiv&space;2&plus;2",
        body: null,
        audio: null
      }
    ],
  },
  "num6-46851": {
    id: "num6-46851",
    equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;%5Cfrac%7B12%7D%7B2%7D%5Cdiv&space;%5Cfrac%7B4%7D%7B3%7D=",
    body: null,
    audio: null,
    correctId: "num6-46851a",
    options: [
      {
        id: "num6-46851a",
        equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;%5Cfrac%7B9%7D%7B2%7D",
        body: null,
        audio: null
      },
      {
        id: "num6-46851b",
        equationURL: null,
        body: "9",
        audio: null
      },
      {
        id: "num6-46851c",
        equationURL: null,
        body: "8",
        audio: null
      },
      {
        id: "num6-46851d",
        equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;%5Cfrac%7B9%7D%7B4%7D",
        body: null,
        audio: null
      }
    ],
  },
  "num6-76575": {
    id: "num6-76575",
    equationURL: null,
    body: `Cuando se resta 3 de un número, el resultado es 7. ¿Cuál es el número original?`,
    audio: `https://storage.googleapis.com/inlier-audio/numeracy/spanish/num6-76575-spanish.mp3`,
    correctId: "num6-76575c",
    options: [
      {
        id: "num6-76575a",
        equationURL: null,
        body: "7",
        audio: null
      },
      {
        id: "num6-76575b",
        equationURL: null,
        body: "4",
        audio: null
      },
      {
        id: "num6-76575c",
        equationURL: null,
        body: "10",
        audio: null
      },
      {
        id: "num6-76575d",
        equationURL: null,
        body: "3",
        audio: null
      }
    ],
  },
  "num7-45704": {
    id: "num7-45704",
    equationURL: null,
    body: `¿Cuál es el valor de x que hace válida esta ecuación?`,
    body2: `x + 3 = 5`,
    audio: "https://storage.googleapis.com/inlier-audio/numeracy/spanish/num7-45704-spanish.mp3",
    correctId: "num7-45704b",
    options: [
      {
        id: "num7-45704a",
        equationURL: null,
        body: "1",
        audio: null
      },
      {
        id: "num7-45704b",
        equationURL: null,
        body: "2",
        audio: null
      },
      {
        id: "num7-45704c",
        equationURL: null,
        body: "8",
        audio: null
      },
      {
        id: "num7-45704d",
        equationURL: null,
        body: "15",
        audio: null
      }
    ],
  },
  "num7-49148": {
    id: "num7-49148",
    equationURL: "https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;3&space;&plus;&space;2(8-6%5Ccdot&space;2)=",
    body: null,
    audio: null,
    correctId: "num7-49148b",
    options: [
      {
        id: "num7-49148a",
        equationURL: null,
        body: "-20",
        audio: null
      },
      {
        id: "num7-49148b",
        equationURL: null,
        body: "-5",
        audio: null
      },
      {
        id: "num7-49148c",
        equationURL: null,
        body: "11",
        audio: null
      },
      {
        id: "num7-49148d",
        equationURL: null,
        body: "20",
        audio: null
      }
    ],
  },
  "num7-83267": {
    id: "num7-83267",
    equationURL: null,
    body: `¿Cuál de los siguientes NO es igual a esta expresión?`,
    body2: `6(2x+4)`,
    audio: "https://storage.googleapis.com/inlier-audio/numeracy/spanish/num7-83267-spanish.mp3",
    correctId: "num7-83267a",
    options: [
      {
        id: "num7-83267a",
        equationURL: null,
        body: `12x + 4`,
        audio: null
      },
      {
        id: "num7-83267b",
        equationURL: null,
        body: `24 + 12x`,
        audio: null
      },
      {
        id: "num7-83267c",
        equationURL: null,
        body: `12(x + 2)`,
        audio: null
      },
      {
        id: "num7-83267d",
        equationURL: null,
        body: `3(2x + 4) + 3(2x + 4) `,
        audio: null
      }
    ],
  },
  "num7-54572": {
    id: "num7-54572",
    equationURL: `https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;(3&space;-&space;2)(3&plus;4(6%5Cdiv&space;2))=`,
    body: null,
    audio: null,
    correctId: "num7-54572a",
    options: [
      {
        id: "num7-54572a",
        equationURL: null,
        body: "15",
        audio: null
      },
      {
        id: "num7-54572b",
        equationURL: null,
        body: "19",
        audio: null
      },
      {
        id: "num7-54572c",
        equationURL: null,
        body: "21",
        audio: null
      },
      {
        id: "num7-54572d",
        equationURL: null,
        body: "28",
        audio: null
      }
    ],
  },
  "num7-43013": {
    id: "num7-43013",
    equationURL: null,
    body: `¿Cuál es el valor de x que hace válida esta ecuación?`,
    body2: `8 - 2x = 2`,
    audio: "https://storage.googleapis.com/inlier-audio/numeracy/spanish/num7-43013-spanish.mp3",
    correctId: "num7-43013b",
    options: [
      {
        id: "num7-43013a",
        equationURL: null,
        body: "2",
        audio: null
      },
      {
        id: "num7-43013b",
        equationURL: null,
        body: "3",
        audio: null
      },
      {
        id: "num7-43013c",
        equationURL: null,
        body: "4",
        audio: null
      },
      {
        id: "num7-43013d",
        equationURL: null,
        body: "6",
        audio: null
      }
    ],
  },
  "num7-19232": {
    id: "num7-19232",
    equationURL: null,
    body: "4 más que un número es 12. ¿Cuál es el valor de ese número?",
    audio: `https://storage.googleapis.com/inlier-audio/numeracy/spanish/num7-19232-spanish.mp3`,
    correctId: "num7-19232b",
    options: [
      {
        id: "num7-19232a",
        equationURL: null,
        body: "3",
        audio: null
      },
      {
        id: "num7-19232b",
        equationURL: null,
        body: "8",
        audio: null
      },
      {
        id: "num7-19232c",
        equationURL: null,
        body: "16",
        audio: null
      },
      {
        id: "num7-19232d",
        equationURL: null,
        body: "48",
        audio: null
      }
    ],
  },
  "num7-46852": {
    id: "num7-46852",
    equationURL: null,
    body: "Abee puede escribir 18 letras en 6 segundos. ¿Cuántas letras puede escribir en 9 segundos?",
    audio: "https://storage.googleapis.com/inlier-audio/numeracy/spanish/num7-46852-spanish.mp3",
    correctId: "num7-46852c",
    options: [
      {
        id: "num7-46852a",
        equationURL: null,
        body: "6",
        audio: null
      },
      {
        id: "num7-46852b",
        equationURL: null,
        body: "18",
        audio: null
      },
      {
        id: "num7-46852c",
        equationURL: null,
        body: "27",
        audio: null
      },
      {
        id: "num7-46852d",
        equationURL: null,
        body: "40",
        audio: null
      }
    ],
  },
  "num7-76576": {
    id: "num7-76576",
    equationURL: null,
    body: "¿Cuánto es el 18% de 150?",
    audio: "https://storage.googleapis.com/inlier-audio/numeracy/spanish/num7-76576-spanish.mp3",
    correctId: "num7-76576d",
    options: [
      {
        id: "num7-76576a",
        equationURL: null,
        body: "9",
        audio: null
      },
      {
        id: "num7-76576b",
        equationURL: null,
        body: "18",
        audio: null
      },
      {
        id: "num7-76576c",
        equationURL: null,
        body: "27",
        audio: null
      },
      {
        id: "num7-76576d",
        equationURL: null,
        body: "36",
        audio: null
      }
    ],
  },
  "num8-45705": {
    id: "num8-45705",
    equationURL: null,
    body: `¿Cuál es el valor de x que hace válida esta ecuación?`,
    body2: `x² = 25`,
    audio: `https://storage.googleapis.com/inlier-audio/numeracy/spanish/num8-45705-spanish.mp3`,
    correctId: "num8-45705a",
    options: [
      {
        id: "num8-45705a",
        equationURL: null,
        body: `5`,
        audio: null
      },
      {
        id: "num8-45705b",
        equationURL: null,
        body: `7`,
        audio: null
      },
      {
        id: "num8-45705c",
        equationURL: null,
        body: `12.5`,
        audio: null
      },
      {
        id: "num8-45705d",
        equationURL: null,
        body: `50`,
        audio: null
      }
    ],
  },
  "num8-49149": {
    id: "num8-49149",
    equationURL: null,
    body: `¿Cuántos valores de x hacen que lo siguiente sea cierto?`,
    body2: `x+2 = x+4`,
    audio: `https://storage.googleapis.com/inlier-audio/numeracy/spanish/num8-49149-spanish.mp3`,
    correctId: "num8-49149a",
    options: [
      {
        id: "num8-49149a",
        equationURL: null,
        body: `0, no tiene soluciones que hagan verdadera la ecuación`,
        audio: `https://storage.googleapis.com/inlier-audio/numeracy/spanish/num8-49149a-spanish.mp3`
      },
      {
        id: "num8-49149b",
        equationURL: null,
        body: `1, tiene una solución que hace verdadera la ecuación`,
        audio: `https://storage.googleapis.com/inlier-audio/numeracy/spanish/num8-49149b-spanish.mp3`
      },
      {
        id: "num8-49149c",
        equationURL: null,
        body: `2, tiene dos soluciones que hacen verdadera la ecuación`,
        audio: `https://storage.googleapis.com/inlier-audio/numeracy/spanish/num8-49149c-spanish.mp3`
      },
      {
        id: "num8-49149d",
        equationURL: null,
        body: `Infinidad de valores, todos los valores de x hacen que la ecuación sea verdadera`,
        audio: `https://storage.googleapis.com/inlier-audio/numeracy/spanish/num8-49149d-spanish.mp3`
      }
    ],
  },
  "num8-83268": {
    id: "num8-83268",
    equationURL: null,
    body: `¿Entre qué dos números está √10?`,
    audio: `https://storage.googleapis.com/inlier-audio/numeracy/spanish/num8-83268-spanish.mp3`,
    correctId: "num8-83268c",
    options: [
      {
        id: "num8-83268a",
        equationURL: null,
        body: `1 y 2`,
        audio: null
      },
      {
        id: "num8-83268b",
        equationURL: null,
        body: `2 y 3`,
        audio: null
      },
      {
        id: "num8-83268c",
        equationURL: null,
        body: `3 y 4`,
        audio: null
      },
      {
        id: "num8-83268d",
        equationURL: null,
        body: `4 y 5`,
        audio: null
      }
    ],
  },
  "num8-54573": {
    id: "num8-54573",
    equationURL: null,
    mathExpressionInstructions: true,
    body: `5² x 5⁶ = `,
    audio: null,
    correctId: "num8-54573a",
    options: [
      {
        id: "num8-54573a",
        equationURL: null,
        body: `5⁸`,
        audio: null
      },
      {
        id: "num8-54573b",
        equationURL: null,
        body: `5¹²`,
        audio: null
      },
      {
        id: "num8-54573c",
        equationURL: null,
        body: `25⁸`,
        audio: null
      },
      {
        id: "num8-54573d",
        equationURL: null,
        body: `25¹²`,
        audio: null
      }
    ],
  },
  "num8-43014": {
    id: "num8-43014",
    equationURL: null,
    body: `¿Cuál es el valor de x que hace válida esta ecuación?`,
    body2: `5x-3 = 3x+5`,
    audio: `https://storage.googleapis.com/inlier-audio/numeracy/spanish/num8-43014-spanish.mp3`,
    correctId: "num8-43014c",
    options: [
      {
        id: "num8-43014a",
        equationURL: null,
        body: `1`,
        audio: null
      },
      {
        id: "num8-43014b",
        equationURL: null,
        body: `3`,
        audio: null
      },
      {
        id: "num8-43014c",
        equationURL: null,
        body: `4`,
        audio: null
      },
      {
        id: "num8-43014d",
        equationURL: null,
        body: `5`,
        audio: null
      }
    ],
  },
  "num8-19233": {
    id: "num8-19233",
    equationURL: `https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;(5-2%5E%7B2%7D)%5Ccdot(9-6%5Cdiv&space;3)=`,
    body: null,
    audio: null,
    correctId: "num8-19233b",
    options: [
      {
        id: "num8-19233a",
        equationURL: null,
        body: `1`,
        audio: null
      },
      {
        id: "num8-19233b",
        equationURL: null,
        body: `7`,
        audio: null
      },
      {
        id: "num8-19233c",
        equationURL: null,
        body: `25`,
        audio: null
      },
      {
        id: "num8-19233d",
        equationURL: null,
        body: `63`,
        audio: null
      }
    ],
  },
  "num8-46853": {
    id: "num8-46853",
    equationURL: null,
    body: `¿Cuál es el valor de x que hace válida esta ecuación?`,
    body2: `2x - 3(4-2) = 4`,
    audio: `https://storage.googleapis.com/inlier-audio/numeracy/spanish/num8-46853-spanish.mp3`,
    correctId: "num8-46853c",
    options: [
      {
        id: "num8-46853a",
        equationURL: null,
        body: `2`,
        audio: null
      },
      {
        id: "num8-46853b",
        equationURL: null,
        body: `4`,
        audio: null
      },
      {
        id: "num8-46853c",
        equationURL: null,
        body: `5`,
        audio: null
      },
      {
        id: "num8-46853d",
        equationURL: null,
        body: `10`,
        audio: null
      }
    ],
  },
  "num8-76577": {
    id: "num8-76577",
    equationURL: `https://latex.codecogs.com/svg.image?%5Cinline&space;%5CLARGE&space;3x&space;-&space;(2x&space;&plus;&space;3)%5Ccdot&space;2=`,
    body: null,
    audio: null,
    correctId: "num8-76577d",
    options: [
      {
        id: "num8-76577a",
        equationURL: null,
        body: `x + 6`,
        audio: null
      },
      {
        id: "num8-76577b",
        equationURL: null,
        body: `-x - 6`,
        audio: null
      },
      {
        id: "num8-76577c",
        equationURL: null,
        body: `-x + 6`,
        audio: null
      },
      {
        id: "num8-76577d",
        equationURL: null,
        body: `x - 6`,
        audio: null
      }
    ],
  }
};


// "": {
//   id: "",
//   equationURL: null,
//   mathExpressionInstructions: null,
//   body: null,
//   audio: null,
//   correctId: "d",
//   options: [
//     {
//       id: "a",
//       equationURL: null,
//       body: null,
//       audio: null
//     },
//     {
//       id: "b",
//       equationURL: null,
//       body: null,
//       audio: null
//     },
//     {
//       id: "c",
//       equationURL: null,
//       body: null,
//       audio: null
//     },
//     {
//       id: "d",
//       equationURL: null,
//       body: null,
//       audio: null
//     }
//   ],
// }